import React, { useState, useEffect } from "react";
import Modal from "components/modal/Modal";
import { highlights } from "./ProductSpecifications";
import { Loader } from "components";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import Select from "react-select";

const EditSpecificationsModal = (props) => {
  console.log(props?.details?.details);
  const [colors, setColors] = useState([]);
  const [specifications, setSpecifications] = useState(highlights);
  const { toastDispatch } = useToastContext();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { error, loading, sendHttpRequest: addSpecifications } = useHttp();
  const {
    error: errorColors,
    loading: loadingColors,
    sendHttpRequest: getColorList,
  } = useHttp();

  const handleSelect = (data) => {
    setSelectedOptions(data);
  };

  useEffect(() => {
    fetchColors();
  }, []);

  const fetchColors = () => {
    try {
      const temp = [];
      getColorList("color_list", "GET", null, ({ data: { colors } }) => {
        colors.map((el) =>
          temp.push({
            value: el._id,
            label: el.name,
            hex: el.hex,
          })
        );
      });
      setColors(temp);
      getFilterDataFun(temp);
    } catch (error) {
      // Handle error
    }
  };

  const getFilterDataFun = (cloData) => {
    if (specifications?.colors?.length) {
      const getFilterAllData = cloData.filter((ele) =>
        specifications.colors.some((elem) => ele.value == elem.value)
      );
      setSelectedOptions(getFilterAllData);
    }
  };

  const addSpecs = async () => {
    try {
      const data = {
        [props.dataKey]: specifications,
      };
      data.highlights["colors"] = selectedOptions.map((option) => ({
        value: option.value,
        label: option.label,
        hex: option.hex,
      }));

      addSpecifications(
        `update_spec/${props.details.details._id}`,
        "PUT",
        data,
        ({ msg }) => {
          toastDispatch({
            type: ADD,
            payload: {
              content: msg,
              type: "success",
            },
          });
          props.refresh();
          props.close();
        }
      );
    } catch (error) {
      // Handle error
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setSpecifications((prevSpecs) => ({
      ...prevSpecs,
      [name]: value,
    }));
  };
 // props?.details?.details?.specs?.highlights?.colors
  useEffect(() => {
    if (props?.details?.details?.specs && props?.details?.details?.specs?.highlights?.colors) {
      setSelectedOptions(props?.details?.details?.specs?.highlights?.colors);
    }
  }, []);
  useEffect(() => {
    if (props?.details?.details?.specs && props?.details?.details?.specs?.highlights) {
      const consoleHighlights = props?.details?.details?.specs?.highlights;
      const updatedHighlights = { ...highlights }; // Copy the original highlights object
  
      // Update each property in the highlights object with data from the console object
      for (const key in updatedHighlights) {
        if (consoleHighlights.hasOwnProperty(key)) {
          updatedHighlights[key] = consoleHighlights[key];
        }
      }
  
      // Set the updated highlights object in the state
      setSpecifications(updatedHighlights);
    }
  }, [props?.details?.details?.specs]);
  return (
    <div>
      <Modal
        close={props.close}
        onAccept={() => addSpecs()}
        title={props.title}
        form="editSpecsForm"
        size="md"
        loading={loading}
        style={{ marginTop: "-30px" }}
      >
        { loadingColors && <Loader />}

        {!loadingColors && (
          <form onSubmit={(e) => e.preventDefault()} id="editSpecsForm">
            <div className="row">
              {Object.entries(specifications).map(
                ([keyName, keyValue], keyIndex) => {
                  if (keyName.toLowerCase() !== "colors") {
                    return (
                      <div
                        className={
                          Object.keys(specifications).length !== 1
                            ? "col-sm-4"
                            : "col-sm-6"
                        }
                        key={keyIndex}
                      >
                        <div className="form-group">
                          <label className="text-capitalize font-weight-bold">
                            {keyName}
                          </label>
                          <textarea
                            name={keyName}
                            type="text"
                            rows="1"
                            value={keyValue}
                            placeholder=""
                            onChange={onChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    );
                  }
                }
              )}
              <div
                className={
                  Object.keys(specifications).length !== 1
                    ? "col-sm-8"
                    : "col-sm-8"
                }
              >
                <div className="col-md-12">
                  <div className="form-group">
                  <label className="font-weight-bold">Colors</label>
                  <Select
                    options={colors}
                    placeholder="Select color"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                  />
                </div> </div>
              </div>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default EditSpecificationsModal;
