import React, { useState, useEffect } from "react";
import ModalService from "components/modal/ModalService";
import AddChannelsModal from "./AddChannelsModal";
import { format } from "date-fns";
import { Loader, Table } from "components";
import { Box, Button, Typography } from '@mui/material';
import TableChartIcon from '@mui/icons-material/TableChart';
import AddIcon from '@mui/icons-material/Add';
import { useHttp } from "hooks";

const Channels = () => {
  const [channelData, setChannels] = useState([]);
  const { error, loading, sendHttpRequest: getChannels } = useHttp();
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setRowCount(channelData?.length);
    setProductsRowCount(channelData?.length);
  }, [channelData]);
  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  const options = {
    customToolbar: () => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            marginBottom: "16px",
            fontSize: "18px",
            marginLeft: "-58px",
          }}
        >
          Total Row Count: <b>{rowCount}</b>
        </div>
      </div>
    ),
    onTableChange: (action, tableState) => {
      if (action === "filterChange" || action === "search") {
        // Update the filtered data when filters change
        setFilteredData(tableState?.displayData?.map((row) => row?.data));
      }
    },
  };
  const getAllChannels = () => {
    getChannels("get_channel", "GET", null, ({ data: { channels } }) =>
      setChannels(channels)
    );
  };
  const addCourierModal = () => {
    ModalService.open(AddChannelsModal, {
      refresh: () => getAllChannels(),
    });
  };
  useEffect(() => {
    getAllChannels();
  }, []);
  const columns = [
    {
      label: "NAME",
      name: "name",
      options: {
        sort: true,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "start" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ fontWeight: "bolder" }}> {value ? value : "-"}</p>;
        },
      },
    },
  ];
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
         <Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  bgcolor="#f5f5f5"
  p={2}
  borderRadius={2}
  boxShadow="0 2px 4px rgba(0,0,0,0.1)"
  mb={2}
>
  {/* Left Side - Total Channels */}
  <Box display="flex" alignItems="center">
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={50}
      height={50}
      borderRadius="50%"
      bgcolor="#e0f7fa"
      mr={2}
    >
      <TableChartIcon style={{ color: "#007bff" }} />
    </Box>
    <Box>
      <Typography variant="h4" fontWeight="bold">
        {rowProductsCount}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Total Channels
      </Typography>
    </Box>
  </Box>

  {/* Right Side - New Channel Button */}
  <Button
    variant="contained"
         className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
    startIcon={<AddIcon />}
    onClick={() => addCourierModal()}
    sx={{
      textTransform: "none",
      fontWeight: "bold",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
    }}
  >
    New Channel
  </Button>
</Box>

<Table
  title={"Channels"}
  data={channelData}
  columns={columns}
  option={options}
/>
        </>
      )}
    </>
  );
};

export default Channels;
