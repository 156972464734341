import React, { useState } from "react";
import { Table } from "components";
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useHttp } from "hooks";
import SearchIcon from "@mui/icons-material/Search";
import { format } from "date-fns";

const ListDevices = () => {
  const [requests, setRequests] = useState([]);
  const navigate = useNavigate();
  const { error, loading, sendHttpRequest: getRepairRequests } = useHttp();
  const [imeiSearchText, setImeiSearchText] = useState(""); // New state for IMEI search
  const [displayedData, setDisplayedData] = useState([]);

  const handleData = ({ data: { assignments } }) => {
    let tempData = [];
    assignments?.forEach((el) => {
      tempData?.push({
        ...(el.corrected
          ? {
              purchase_price: `${
                el.corrected[0]?.calculation?.calcualation?.currency || ""
              } ${
                el.corrected[0]?.calculation?.calcualation?.valueToShowCustomer
                  ? el.corrected[0]?.calculation?.calcualation?.valueToShowCustomer.toLocaleString(
                      "en-US"
                    )
                  : ""
              }`,
            }
          : el.bas
          ? {
              purchase_price: el?.bas?.agreedPrice || "-",
            }
          : {
              purchase_price: `${
                el?.calculation?.calculation?.currency || ""
              } ${
                el?.calculation?.calculation?.valueToShowCustomer
                  ? el?.calculation?.calculation?.valueToShowCustomer.toLocaleString(
                      "en-US"
                    )
                  : ""
              }`,
            }),

        model: el?.model?.modelName || "-",
        qcBy: el?.qcBy ? `${el.qcBy.fname || ""} ${el.qcBy.lname || ""}` : "-",
        assessedBy: el?.assessedBy
          ? `${el.assessedBy.fname || ""} ${el.assessedBy.lname || ""}`
          : "-",
        requestedBy: el?.requestedBy
          ? `${el.requestedBy.fname || ""} ${el.requestedBy.lname || ""}`
          : "-",

        assignTechTimeStamp: el?.repair?.assignTechTimeStamp
          ? format(
              new Date(el.repair.assignTechTimeStamp),
              "dd-MMM-yyyy HH:mm a"
            )
          : "-",
        completion_date: el?.repair?.completion_date
          ? format(new Date(el.repair.completion_date), "dd-MMM-yyyy HH:mm a")
          : "-",
        CompleteRepairTimeStamp: el?.repair?.CompleteRepairTimeStamp
          ? format(
              new Date(el.repair.CompleteRepairTimeStamp),
              "dd-MMM-yyyy HH:mm a"
            )
          : "-",
        PartsApprovalTimeStamp: el?.repair?.PartsApprovalTimeStamp
          ? format(
              new Date(el.repair.PartsApprovalTimeStamp),
              "dd-MMM-yyyy HH:mm a"
            )
          : "-",
        requestPartsTimeStamp: el?.repair?.requestPartsTimeStamp
          ? format(
              new Date(el.repair.requestPartsTimeStamp),
              "dd-MMM-yyyy HH:mm a"
            )
          : "-",

        variant:
          el.variant?.length && el.variant[0]?.ram !== undefined
            ? `${el.variant[0]?.ram}GB/${
                el.variant[0]?.storage + el.variant[0]?.storageType
              }`
            : "-",

        createdAt: el?.createdAt
          ? format(new Date(el?.createdAt), "dd-MMM-yyyy HH:mm a")
          : "-",
        grade: el?.grade || "-",
        store: el.bas ? el?.dealers?.name || "-" : "website",
         salesagent: el?.bas?.agentName?el?.bas?.agentName :'-',
         warehouse: el.warehouse ?el.warehouse: "-",
        imei: el?.bas?.imei || el?.imei || el?.inventories?.imei || "-",
        factorySerialNumber:
          el?.bas?.factorySerialNumber ||
          el?.factorySerialNumber ||
          el?.inventories?.factorySerialNumber ||
          "-",
        source: el?.calculation?.source?.toUpperCase() || "-",
        serial_num: el?.inventories?.serialNumber || "-",
        tat: el?.tat || "-",
        status: Array.isArray(el?.device_status)
          ? el.device_status.map((s) => s.name || "-").join(", ")
          : "-", // Converts array of objects to a string

        _id: el?.calculationId || "-",
        tech_id: el?.teamLeaderId || "-",
        device_id: el?._id || "-",
        returnDepartment: el?.returnDepartment || "-",
        returnToWIPfromSoldTimeStamp: el?.returnToWIPfromSoldTimeStamp
          ? format(
              new Date(el?.returnToWIPfromSoldTimeStamp),
              "dd-MMM-yyyy HH:mm a"
            )
          : "-",
        returnToWipFromSoldReason: el?.returnToWipFromSoldReason || "-",
      });
    });

    setDisplayedData(tempData);
    setRequests(tempData);
  };

  const handleImeiSearch = () => {
    getRepairRequests(
      "teamleader_device_history",
      "POST",
      { searchText: imeiSearchText },
      handleData
    );
  };

  const handleView = (id) => {
    // navigate(
    //   `/${
    //     UserService.getCurrentUserLoginData().country
    //   }/technician/repair/${id}`
    // );
  };
  const columns = [
    // {
    //   label: "Action",
    //   name: "_id",
    //   options: {
    //     filter: false,
    //     print: false,
    //     setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <IconButton
    //           size="small"
    //           color="primary"
    //           aria-label="upload picture"
    //           component="span"
    //           title="view"
    //           onClick={() => handleView(value)}
    //         >
    //           <i className="icofont icofont-eye" />
    //         </IconButton>
    //       );
    //     },
    //   },
    // },
    {
      label: "Model",
      name: "model",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Variant",
      name: "variant",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Factory Serial Number",
      name: "factorySerialNumber",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          {
            return value ? value : "-";
          }
        },
      },
    },
    {
      label: "Serial No.",
      name: "serial_num",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Source",
      name: "source",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Grade",
      name: "grade",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value?.toLowerCase() === "a" && (
                <span className="text-success">{value}</span>
              )}
              {value?.toLowerCase() === "b" && (
                <span className="text-warning">{value}</span>
              )}
              {value?.toLowerCase() === "c" && (
                <span className="text-danger">{value}</span>
              )}
              {value?.toLowerCase() === "d" && <span>{value}</span>}
              {value === " " && <span>{"-"}</span>}
            </>
          );
        },
      },
    },
    {
      label: "Request Date",
      name: "createdAt",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Due Repair Date",
      name: "tat",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Request Status",
      name: "status",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Sales agent",
      name: "salesagent",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Warehouse",
      name: "warehouse",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    // {
    //   label: "QC By",
    //   name: "qcBy",
    //   options: {
    //     filter: true,
    //     setCellProps: () => ({ style: { whiteSpace: "pre" } }),
    //   },
    // },
    {
      label: "Repaired By",
      name: "assessedBy",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Team Leader",
      name: "requestedBy",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Return Department",
      name: "returnDepartment",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Return To WIP from Sold TimeStamp",
      name: "returnToWIPfromSoldTimeStamp",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Return To Wip From Sold Reason",
      name: "returnToWipFromSoldReason",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Assigned Technician Date/Time",
      name: "assignTechTimeStamp",
      options: {
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Completed Repair Date/Time",
      name: "CompleteRepairTimeStamp",
      options: {
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Requested Parts Date/Time",
      name: "requestPartsTimeStamp",
      options: {
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Parts Approval Date/Time",
      name: "PartsApprovalTimeStamp",
      options: {
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
  ];
  return (
    <Box p={3}>
      {/* Search Section */}
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          padding: 2,
          borderRadius: "12px",
          maxWidth: "600px",
          mx: "auto",
          mb: 3,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          label="Enter IMEI or Serial Number"
          value={imeiSearchText}
          onChange={(e) => setImeiSearchText(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
           className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
          onClick={handleImeiSearch}
          sx={{ height: "56px", px: 3, borderRadius: "8px" }}
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
      </Paper>

      {/* Loading State */}
      {loading ? (
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            borderRadius: "12px",
            p: 3,
          }}
        >
          <CircularProgress />
        </Paper>
      ) : (
        // Dashboard Table
        <Paper elevation={3} sx={{ borderRadius: "12px", p: 3 }}>
          <Table title="Search" data={requests} columns={columns} />
        </Paper>
      )}
    </Box>
  );
};

export default ListDevices;
