import ListDevices from "./ListDevices";

const GlobalSearch = [
  {
    path: "/global-search",
    element: <ListDevices />,
  },
];

export default GlobalSearch;
