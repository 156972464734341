import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AssignRepModal from "./AssignRepresentativeModal";
import ModalService from "components/modal/ModalService";
import { getAgents } from "./RepresentativeService";
import { Loader } from "components";
import Avatar from "assets/images/avatar.png";
import { useHttp } from "hooks";

const cardStyles = {
  minHeight: "150px",
  maxWidth: "100%",
  overflow: "hidden",
};

const mediaBodyStyles = {
  wordWrap: "break-word",
  whiteSpace: "normal",
  overflow: "hidden",
};

const textMutedStyles = {
  display: "block",
  wordBreak: "break-word",
};
const Represenatatives = () => {
  const [reps, setReps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const {
    error,
    loading: loadingRegions,
    sendHttpRequest: getRegions,
  } = useHttp();

  const getAllReps = async () => {
    setLoading(true);
    try {
      const { msg, success, data } = await getAgents();
      getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
        setRegions(regions)
      );
      if (success) {
        setReps(data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const openModal = (rep) => {
    ModalService.open(AssignRepModal, {
      refresh: () => getAllReps(),
      details: rep,
    });
  };
  useEffect(() => {
    getAllReps();
  }, []);
  return (
    <div>
      {loading && <Loader />}
      {!loading && (
        <div className="card">
          <div className="card-header">
            <div className="header-elements-inline">
              <h4 className="font-weight-semibold mb-1">Badili Agents</h4>
              <div className="header-elements"></div>
            </div>
          </div>

          <div className="card-body">
    {reps.length === 0 ? (
      <div className="d-flex justify-content-center align-items-center">
        <b>No data available</b>
      </div>
    ) : (
      <div className="row">
        {reps.map((rep) => (
          <div className="col-xl-3 col-md-6" key={rep._id}>
            <div className="card card-body" style={cardStyles}>
              <div className="media">
                <div className="mr-3">
                  <img
                    src={Avatar}
                    className="rounded"
                    alt="user"
                    width={55}
                    height={55}
                  />
                </div>
                <div className="media-body" style={mediaBodyStyles}>
                  <div className="font-weight-semibold">
                    {rep.fname + " " + rep.lname}
                  </div>
                  <div className="text-muted" style={textMutedStyles}>
                    {rep?.dealer[0]?.name || "Unassigned"}
                  </div>
                  <span  style={textMutedStyles}>
                    {rep.phone}
                  </span>
                </div>
                <div className="ml-3 align-self-center">
                  <div className="list-icons">
                    <div className="list-icons-item dropdown">
                      <a
                        href="#"
                        className="list-icons-item dropdown-toggle caret-0"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="icon-menu7" />
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        style={{
                          position: "absolute",
                          willChange: "transform",
                          top: 0,
                          left: 0,
                          transform: "translate3d(16px, 16px, 0px)",
                        }}
                      >
                        <Link
                          to={`/sales/representative/${rep._id}`}
                          state={{ agent: rep }}
                          className="dropdown-item"
                        >
                          <i className="icon-file-eye" /> Profile
                        </Link>
                        <a
                          href="#"
                          className="dropdown-item"
                          onClick={() => openModal(rep)}
                        >
                          <i className="icon-reading" />
                          {rep.dealer.length < 1
                            ? "Assign Dealer"
                            : "Reassign Dealer"}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
        </div>
      )}
    </div>
  );
};

export default Represenatatives;
