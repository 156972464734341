import React from "react";
import { useNavigate } from "react-router-dom";

const PageHeader = (props) => {
  const navigate = useNavigate();
  return (
    <>
      {/* Page header */}
      <div className="page-header page-header-light">
        <div className="page-header-content header-elements-md-inline">
          <div className="page-title d-flex">
            <h4>
              {/* <i className="icon-arrow-left52 mr-2" />{" "} */}
              <i className="icon-home2 mr-2" /> <span className="font-weight-semibold">Home</span> {props?.data? '-'+' '+props?.data:''} 
            </h4>
            <a
              href="#"
              className="header-elements-toggle text-default d-md-none"
            >
              <i className="icon-more" />
            </a>
          </div>
          <div className="header-elements d-none">
            <div className="d-flex justify-content-center">
            <div className="breadcrumb justify-content-center p-0">
              {/* <a href="#" className="breadcrumb-elements-item">
                <i className="icon-comment-discussion mr-2" />
                Support
              </a> */}
              <div className="breadcrumb-elements-item dropdown p-0">
                <button
                  className="breadcrumb-elements-item dropdown-toggle border-0 outline-0 bg-transparent"
                  data-toggle="dropdown"
                >
                  <i className="icon-gear mr-2" />
                  Settings
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item border-0 bg-transparent outline-0">
                    <i className="icon-user-lock" /> Account security
                  </button>
                  <button
                    className="dropdown-item border-0 bg-transparent outline-0"
                    onClick={() => navigate(`/sales/shipping-delivery`)}
                  >
                    <i className="icon-truck" /> Shipping & Delivery
                  </button>
                  <button
                    className="dropdown-item border-0 bg-transparent outline-0"
                    onClick={() => navigate(`/inventory/product-configuration`)}
                  >
                    <i className="icon-mobile" /> Product Configuration
                  </button>
                </div>
              </div>
            </div>
              {/* <a
                href="#"
                className="btn btn-link btn-float font-size-sm font-weight-semibold text-default"
              >
                <i className="icon-bars-alt text-pink-300" />
                <span>Statistics</span>
              </a>
              <a
                href="#"
                className="btn btn-link btn-float font-size-sm font-weight-semibold text-default"
              >
                <i className="icon-calculator text-pink-300" />
                <span>Invoices</span>
              </a>
              <a
                href="#"
                className="btn btn-link btn-float font-size-sm font-weight-semibold text-default"
              >
                <i className="icon-calendar5 text-pink-300" />
                <span>Schedule</span>
              </a> */}
            </div>
          </div>
        </div>
        {/* <div className="breadcrumb-line breadcrumb-line-light header-elements-md-inline">
          <div className="d-flex">
            <div className="breadcrumb p-0">
              <a href="index.html" className="breadcrumb-item">
                <i className="icon-home2 mr-2" /> Home
              </a>
              <span className="breadcrumb-item active">Dashboard</span>
            </div>
            <a href className="header-elements-toggle text-default d-md-none">
              <i className="icon-more" />
            </a>
          </div>
          <div className="header-elements d-none">
            <div className="breadcrumb justify-content-center p-0">
              <a href="#" className="breadcrumb-elements-item">
                <i className="icon-comment-discussion mr-2" />
                Support
              </a>
              <div className="breadcrumb-elements-item dropdown p-0">
                <button
                  className="breadcrumb-elements-item dropdown-toggle border-0 outline-0 bg-transparent"
                  data-toggle="dropdown"
                >
                  <i className="icon-gear mr-2" />
                  Settings
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item border-0 bg-transparent outline-0">
                    <i className="icon-user-lock" /> Account security
                  </button>
                  <button
                    className="dropdown-item border-0 bg-transparent outline-0"
                    onClick={() => navigate(`/sales/shipping-delivery`)}
                  >
                    <i className="icon-truck" /> Shipping & Delivery
                  </button>
                  <button
                    className="dropdown-item border-0 bg-transparent outline-0"
                    onClick={() => navigate(`/inventory/product-configuration`)}
                  >
                    <i className="icon-mobile" /> Product Configuration
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* /page header */}
    </>
  );
};

export default PageHeader;
