import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AppContext } from "context/AppContext";
import { useToastContext, ADD } from "context/ToastContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import {
  Container,
  Card,
  Typography,
  Button,
  Box,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
  InputLabel,
  FormControl,
} from "@mui/material";
import ImageLogoBadili from "../../assets/images/pdfLogo/badili-logo.jpg";
import { useHttp } from "hooks";

const Login = () => {
  const navigate = useNavigate();
  const { toastDispatch } = useToastContext();
  const [showOTP, setShowOtp] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    country: "",
    password: "",
    role: "",
  });
  const [getLogPass, setLogPass] = useState(null);
  const [useLogin, setUseLogin] = useState({
    id: "",
    otp: "",
  });
  const { setUser } = useContext(AppContext);
  const {
    error: errorOTP,
    loading: loadingOTP,
    sendHttpRequest: requestOTP,
  } = useHttp();
  const {
    error: errorResend,
    loading: loadingResend,
    sendHttpRequest: resend,
  } = useHttp();
  const {
    error: errorRoles,
    loading: loadingRoles,
    sendHttpRequest: getRoles,
  } = useHttp();
  const {
    error: errorAuth,
    loading: loadingAuth,
    sendHttpRequest: login,
  } = useHttp();

  useEffect(() => {
    getRoles("get_role_sales", "GET", null, ({ data: { roles } }) => {
      setCredentials({
        ...credentials,
        role: roles.find((role) => role.name.toLowerCase() === "sales")._id,
      });
    });
  }, []);

  const handleOTPChange = (otp) => setUseLogin({ ...useLogin, otp });

  const onChange = (e) => {
    const { name, value } = e.target;
    console.log({ name, value });

    setCredentials({ ...credentials, [name]: value });
  };

  const requestOneTimePass = (val) => {
    sessionStorage.clear();
    if(val?.password){
      setLogPass(val.password)
    }
    let _data = {
      auth: val.username,
      country: val.country,
      password: val.password,
      role: credentials.role,
    };
    requestOTP("admin_login", "POST", _data, ({ data: { admId }, msg }) => {
      setUseLogin({ ...useLogin, id: admId });
      setShowOtp(true);
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
  };
  const resendOTPass = (data) => {
    sessionStorage.clear();
    // e.preventDefault();
    setUseLogin({ ...useLogin, otp: "" });
    let _data = {
      admId: useLogin.id,
      password: getLogPass,
    };
    resend("resend_otp", "POST", _data, ({ msg }) => {
      toastDispatch({
        type: ADD,
        payload: {
          content: msg,
          type: "success",
        },
      });
    });
    setUseLogin({ ...useLogin, otp: "" });
  };

  const authenticate = async (values) => {
    sessionStorage.clear();
    let data = {
      admId: useLogin.id,
      otp: Number(values.otp),
    };

    login("checkadminotp", "POST", data, ({ data: { token, user } }) => {
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("token", token);
      setUser(user);
      navigate("/sales/global-search");
      toastDispatch({
        type: ADD,
        payload: {
          content: `Welcome ${
            user.fname + " " + user.lname
          } to your Dashboard!`,
          type: "info",
        },
      });
      setUseLogin({ ...useLogin, otp: "" });
    });
  };
  return (
    <>
      <Container maxWidth="sm">
        <Card
          sx={{
            p: 4,
            mt: 8,
            boxShadow: 3,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Box display="flex" justifyContent="center" mb={2}>
            <img src={ImageLogoBadili} alt="Sales Dashboard" width={120} />
          </Box>
          {!showOTP ? (
            <Formik
              initialValues={credentials}
              validationSchema={Yup.object({
                country: Yup.string().required("Country is required"),
                username: Yup.string()
                  .email("Enter a valid email")
                  .required("Email is required"),
                password: Yup.string().required("Password is required"),
              })}
              onSubmit={requestOneTimePass}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form>
                  <Typography variant="h6" fontWeight="bold">
                    Login to Your Sales Account
                  </Typography>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Enter your credentials below
                  </Typography>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.country && touched.country)}
                    sx={{ mb: 2 }}
                  >
                    {/* <InputLabel>Select your Country</InputLabel> */}
                    <Select
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value="">Select your Country</MenuItem>
                      <MenuItem value="ke">🇰🇪 Kenya</MenuItem>
                      <MenuItem value="ug">🇺🇬 Uganda</MenuItem>
                      <MenuItem value="tz">🇹🇿 Tanzania</MenuItem>
                      <MenuItem value="in">🇮🇳 India</MenuItem>
                      <MenuItem value="ng">🇳🇬 Nigeria</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errors.country && touched.country ? errors.country : ""}
                    </FormHelperText>
                  </FormControl>

                  <Field
                    as={TextField}
                    name="username"
                    label="Email"
                    fullWidth
                    onChange={handleChange}
                    variant="outlined"
                    sx={{ mb: 2 }}
                    error={touched.username && !!errors.username}
                    helperText={touched.username && errors.username}
                  />

                  <Field
                    as={TextField}
                    name="password"
                    label="Password"
                    type="password"
                    onChange={handleChange}
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                    error={touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                  />

                  <Button
                    type="submit"
                    className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loadingOTP}
                  >
                    {loadingOTP ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Request OTP"
                    )}
                  </Button>
                  <Typography variant="body2" color="textSecondary" mt={2}>
                    <Link to="/forgot-password">Forgot password?</Link>
                  </Typography>
                </Form>
              )}
            </Formik>
          ) : (
            <Formik
              initialValues={useLogin}
              validationSchema={Yup.object({
                otp: Yup.string().required("OTP is required"),
              })}
              onSubmit={(values) => authenticate(values)}
            >
              {({ errors, values, touched, handleChange, setFieldValue }) => (
                <Form>
                  <Typography variant="h6">Enter OTP</Typography>
                  <Typography variant="body2" color="textSecondary" mb={2}>
                    Enter the OTP sent to your email
                  </Typography>
                  <Box display="flex" justifyContent="center" mb={2}>
                    <OtpInput
                      value={values.otp}
                      onChange={(otp) => setFieldValue("otp", otp)}
                      numInputs={4}
                      isInputNum
                      separator={<span>-</span>}
                      inputStyle={{
                        width: "3em",
                        height: "3em",
                        fontSize: "1.5em",
                        border:
                          touched.otp && errors.otp
                            ? "1px solid red"
                            : "1px solid #2196f3",
                        margin: "0.5em",
                      }}
                    />
                  </Box>
                  <ErrorMessage
                    name="otp"
                    component="div"
                    style={{ color: "red" }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
                    variant="contained"
                    color="primary"
                    disabled={loadingAuth}
                  >
                    {loadingAuth ? <CircularProgress size={24} /> : "Sign in"}
                  </Button>
                  <Typography variant="body2" color="textSecondary" mt={2}>
                    Didn't receive OTP?{" "}
                    <span
                      onClick={resendOTPass}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      Resend
                    </span>
                  </Typography>
                </Form>
              )}
            </Formik>
          )}
        </Card>
      </Container>
    </>
  );
};
export default Login;
