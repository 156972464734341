import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { Loader } from "components";
import { useHttp } from "hooks";
import AddCityModal from "./AddCityModal";
import ModalService from "components/modal/ModalService";

const ListCity = () => {
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  const {
    loading,
    sendHttpRequest: getcities,
    sendHttpRequest: getRegions,
  } = useHttp();

  const getAllCities = async () => {
    await getcities("get_all_cities", "GET", null, ({ data: { cities } }) =>
      setCities(cities)
    );
    await getRegions("get_regions", "GET", null, ({ data: { regions } }) =>
      setRegions(regions)
    );
  };

  const openModal = () => {
    ModalService.open(AddCityModal, { refresh: () => getAllCities() });
  };

  useEffect(() => {
    getAllCities();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div className="card shadow-sm border-0">
          {/* Header Section */}
          <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
            <h5 className="mb-0">City Management</h5>
            <div className="d-flex align-items-center gap-3">
              <div className="d-flex align-items-center gap-2">
                <i className="bi bi-building text-white"></i>
                <span>Total Cities: {cities?.length}</span>
              </div> &nbsp;&nbsp;
              <button className="btn btn-sm btn-light" onClick={openModal}>
                + Add City
              </button>
            </div>
          </div>
          {/* Body Section */}
          <div className="card-body p-3">
            {/* <h6 className="text-muted mb-3">List of Active Cities</h6> */}
            {cities?.length === 0 ? (
              <div className="alert alert-warning text-center">
                No available cities.
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-sm table-hover table-bordered table-striped align-middle">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">City Name</th>
                      <th scope="col">Region Name</th>
                      <th scope="col">Created Date</th>
                      <th scope="col" className="text-center">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cities?.map((city) => (
                      <tr key={city?._id}>
                        <td>{city?.name}</td>
                        <td>
                          {regions?.find(
                            (region) => region?._id === city?.region
                          )?.name || "-"}
                        </td>
                        <td>{format(new Date(city?.createdAt), "PPpp")}</td>
                        <td className="text-center">
                          <span
                            className={`badge ${
                              city?.active ? "bg-success" : "bg-danger"
                            }`}
                          >
                            {city?.active ? "Active" : "Inactive"}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListCity;
