import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loader, Table } from "components";
import { format } from "date-fns";
import ModalService from "components/modal/ModalService";
import SetAsUnsoldModal from "./SetAsUnsoldModal";
import { Button } from "antd";
import { Container } from "@mui/material";
import { useHttp } from "hooks";
import SoldDevicesModel from "./SoldDevicesModel";
import { useInvetorySold } from "./hooks/useinventorySold";

const SoldDevicesTable = () => {
  const { soldDevices, isLoading: gettingInventory } = useInvetorySold();
  // COMPUTED PROPERTIES
  const totalProducts = soldDevices?.requests?.length;
  const importedProducts = soldDevices?.requests?.filter(
    (device) => device?.source.toLowerCase() === "imported"
  ).length;
  const onlineProducts = soldDevices?.requests?.filter(
    (device) => device?.source.toLowerCase() === "online"
  ).length;
  const stockProducts = soldDevices?.requests?.filter(
    (device) => device?.source.toLowerCase() === "stock"
  ).length;
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const [rowCount, setRowCount] = useState(null);
  const [selectedRepairRequest, setSelectedRepairRequest] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [showAttachedParts, setAttachedParts] = useState([]);
  const [imeiSearchText, setImeiSearchText] = useState("");
  const [serialSearchText, setSerialSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const opensetAsUnsoldModal = (id, name) => {
    ModalService.open(SetAsUnsoldModal, {
      id: id,
      name,
    });
  };
  const SoldDevicesModelfun = (repairData) => {
    ModalService.open(SoldDevicesModel, {
      selectedRepairRequest: repairData,
      tableData: showAttachedParts,
    });
  };

  const handleSoldDeviceOrders = soldDevices?.requests?.map((el) => ({
    date: format(new Date(el?.createdAt), "dd MMM yyyy"),
    model: el?.model?.modelName,
    _id: el?._id,
    model_id: el?.model?._id,
    variant:
      el?.variant[0]?.ram !== undefined
        ? `${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType}`
        : " ",
    imei: el?.imei || "-",
    factorySerialNumber: el?.factorySerialNumber || "-",
    source:
      el?.source !== undefined && el?.source ? el?.source?.toUpperCase() : "-",
    assigned_store: el?.stores !== undefined && el?.stores?.name,
    rrp: el?.variant[0]?.discounted,
    wsp: el?.variant[0]?.wsp,
    //color: el?.colorSet ? el.assignedColor[0]?.name : "",
    color: el?.colorSet,
    sold: el?.sold,
    soldDate: el?.model?.createdAt
      ? format(new Date(el?.model?.createdAt), "dd MMM yyyy")
      : "",
    rts: el?.rts,
    action: [
      el?._id,
      el?.sold,
      el?.model?.modelName,
      el?.model?._id,
      el,
      el?.colorSet,
    ],
    // status: el?.current_status[0]?.name,
    status: "",
  }));

  useEffect(() => {
    setPageData({ rowData: gettingInventory ? [] : handleSoldDeviceOrders });
  }, [gettingInventory, soldDevices]);

  const columns = [
    {
      label: "DATE",
      name: "date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <h6 className="mb-0">
                {format(new Date(value), "dd")} {format(new Date(value), "MMM")}
              </h6>
              <div className="font-size-sm text-muted line-height-1">
                {format(new Date(value), "yyyy")}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "model_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Product Name",
      name: "model",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                <Link
                  style={{ fontWeight: 600 }}
                  to={`/inventory/product-configuration/${tableMeta.rowData[1]}`}
                  className="letter-icon-title"
                >
                  {value}
                </Link>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "VARIANT",
      name: "variant",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="font-weight-semibold">{value}</div>
            </>
          );
        },
      },
    },
    // {
    //   label: "Color",
    //   name: "color",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <>{value ? <b>{value}</b> : <p>Color not set</p>}</>;
    //     },
    //   },
    // },
    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
      },
    },
    {
      label: "Factory Serial Number",
      name: "factorySerialNumber",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          {
            return value ? value : "-";
          }
        },
      },
    },

    {
      label: "sold date",
      name: "soldDate",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : "-"}</>;
        },
      },
    },
    // {
    //   label: "Available",
    //   name: "sold",
    //   options: {
    //     filter: true,
    //     filterType: "custom",
    //     customFilterListOptions: {
    //       render: (v) => {
    //         if (v.length !== 0) {
    //           return !v[0] ? "Yes" : "No";
    //         }
    //         return false;
    //       },
    //     },
    //     filterOptions: {
    //       names: [],
    //       logic(status, filter) {
    //         if (filter.length > 0) {
    //           return status !== filter[0];
    //         }
    //         return false;
    //       },
    //       display: (filterList, onChange, index, column) => (
    //         <FormControl variant="standard">
    //           <InputLabel id="demo-simple-select-standard-label">
    //             Available
    //           </InputLabel>
    //           <Select
    //             labelId="demo-simple-select-standard-label"
    //             onChange={(event) => {
    //               filterList[index][0] = event.target.value;
    //               onChange(filterList[index], index, column);
    //             }}
    //             value={filterList[index]}
    //           >
    //             <MenuItem value={false}>{"Yes"}</MenuItem>
    //             <MenuItem value={true}>{"No"}</MenuItem>
    //           </Select>
    //         </FormControl>
    //       ),
    //     },
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "center" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           {value ? (
    //             <span className="text-warning text-center">No</span>
    //           ) : (
    //             <span className="text-success text-center">Yes</span>
    //           )}
    //         </>
    //       );
    //     },
    //   },
    // },
    // {
    //   label: "RTS",
    //   name: "rts",
    //   options: {
    //     filter: true,
    //     filterType: "custom",
    //     customFilterListOptions: {
    //       render: (v) => {
    //         if (v.length !== 0) {
    //           return v[0] ? "Yes" : "No";
    //         }
    //         return false;
    //       },
    //     },
    //     filterOptions: {
    //       names: [],
    //       logic(status, filter) {
    //         if (filter.length > 0) {
    //           return status !== filter[0];
    //         }
    //         return false;
    //       },
    //       display: (filterList, onChange, index, column) => (
    //         <FormControl variant="standard">
    //           <InputLabel id="demo-simple-select-standard-label">
    //             RTS
    //           </InputLabel>
    //           <Select
    //             labelId="demo-simple-select-standard-label"
    //             onChange={(event) => {
    //               filterList[index][0] = event.target.value;
    //               onChange(filterList[index], index, column);
    //             }}
    //             value={filterList[index]}
    //           >
    //             <MenuItem value={true}>{"Yes"}</MenuItem>
    //             <MenuItem value={false}>{"No"}</MenuItem>
    //           </Select>
    //         </FormControl>
    //       ),
    //     },
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "center" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           {value ? (
    //             <span className="text-success text-center">Yes</span>
    //           ) : (
    //             <span className="text-danger text-center">No</span>
    //           )}
    //         </>
    //       );
    //     },
    //   },
    // },
    // {
    //   label: "Current Status",
    //   name: "status",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return value;
    //     },
    //   },
    // },
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <div className="list-icons-item dropdown">
                  <button
                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                    data-toggle="dropdown"
                  >
                    <i className="icon-menu7" />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    {/* {!value[1] && (
                      <>
                        <a
                          href
                          className="dropdown-item"
                          onClick={() => openSoldModal(value[0])}
                        >
                          <i className="icon-gear" /> Mark as Sold
                        </a>
                      </>
                    )} */}
                    {value[1] ? (
                      <>
                        <a
                          href
                          className="dropdown-item"
                          onClick={() =>
                            opensetAsUnsoldModal(value[0], value[2])
                          }
                        >
                          <i className="icon-dash" /> Mark as Returned
                        </a>
                      </>
                    ) : (
                      ""
                    )}

                    {/* <a
                      href
                      disabled
                      className="dropdown-item"
                      onClick={() =>
                        openAddColorModal(
                          value[3],
                          value[0],
                          value[5] ? "Update Color" : "Add Color"
                        )
                      }
                    >
                      <i className="icon-add" />
                      {value[5] ? "Update" : "Add"} Color
                    </a>
                    <a
                      href
                      disabled
                      className="dropdown-item"
                      onClick={() => openStoreModal(value[4])}
                    >
                      <i className="icon-stack-check" />
                      Assign to Store
                    </a> */}
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    },
  ];

  const handleImeiSearch = () => {
    setPageData({
      isLoading: false,
      rowData: [],
      totalProducts: 0,
    });
    // Normalize IMEI and serial inputs (split by comma or newline, trim, and filter empty values)
    const imeis = imeiSearchText
      .split(/[,\n]+/) // Use + to handle multiple commas/newlines
      .map((imei) => imei.trim())
      .filter((imei) => imei.length > 0);
    const serials = serialSearchText
      .split(/[,\n]+/)
      .map((serial) => serial.trim())
      .filter((serial) => serial.length > 0);
    // Ensure at least one valid IMEI or serial is provided before filtering
    if (imeis.length === 0 && serials.length === 0) {
      setFilteredData(pageData.rowData); // Reset to full data if no search terms
      return;
    }
    // Filter data by IMEIs or serials
    const filteredData = pageData.rowData.filter((item) => {
      const imeiMatch = imeis.some((imei) => item.imei.includes(imei));
      const serialMatch = serials.some((serial) =>
        item.factorySerialNumber.includes(serial)
      );
      return imeiMatch || serialMatch;
    });
    setPageData({
      isLoading: false,
      rowData: filteredData,
      totalProducts: filteredData.length,
    });
  };

  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected?.map(
      ({ dataIndex }) => handleSoldDeviceOrders[dataIndex]
    );

    let tempObjData = [];
    let tempObjData2 = [];
    selectedData?.map((data, index) => {
      tempObjData2.push({
        model: data?.model + "(" + data?.variant + ")",
        color: data?.color,
        imei: data?.imei,
        zind: index + 1,
      });
      tempObjData.push({
        details_id: data?._id,
      });
    });
    setSelectedRepairRequest(tempObjData);
    setAttachedParts(tempObjData2);
    setSelectedRowsData(selectedData);
  };

  const handleResetSearch = () => {
    setImeiSearchText("");
    setSerialSearchText("");
    setPageData({ rowData: gettingInventory ? [] : handleSoldDeviceOrders });
  };
  return (
    <>
      {gettingInventory && <Loader />}
      {!gettingInventory && (
        <div>
          <div className="card-body d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <div id="tickets-status" />
              <button className="btn bg-transparent   rounded-round border-2 btn-icon">
                <i class="icomoon icon-table"></i>
              </button>
              <div className="ml-3">
                <h3
                  style={{ textAlign: "center" }}
                  className="font-weight-semibold mb-0"
                >
                  {totalProducts}
                </h3>
                <span>Total Sold Devices</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button className="btn bg-transparent rounded-round border-2 btn-icon">
                <i class="icomoon icon-truck"></i>
              </button>
              <div className="ml-3">
                <h3
                  style={{ textAlign: "center" }}
                  className="font-weight-semibold mb-0"
                >
                  {importedProducts}
                </h3>
                <span> International (imported) </span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button className="btn bg-transparent rounded-round border-2 btn-icon">
                <i class="icomoon icon-laptop"></i>
              </button>
              <div className="ml-3">
                <h3
                  style={{ textAlign: "center" }}
                  className="font-weight-semibold mb-0"
                >
                  {onlineProducts}
                </h3>
                <span>Local Buyback (online)</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              <button className="btn bg-transparent rounded-round border-2 btn-icon">
                <i class="icomoon icon-office"></i>
              </button>
              <div className="ml-3">
                <h3
                  style={{ textAlign: "center" }}
                  className="font-weight-semibold mb-0"
                >
                  {stockProducts}
                </h3>
                <span>Stock </span>
              </div>
            </div>
          </div>

          <Container
            style={{
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
              marginBottom: "16px",
              maxWidth: "100%",
              textAlign: "center",
            }}
          >
            <h3
              style={{
                marginBottom: "12px",
                fontSize: "20px",
                fontWeight: "600",
                color: "#58cab9",
              }}
            >
              🔎 Search Devices
            </h3>

            <div
              className="search-box"
              style={{
                display: "flex",
                gap: "16px",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {/* IMEI Search */}
              <div style={{ flex: "1 1 280px" }}>
                <label
                  style={{
                    fontWeight: "500",
                    marginBottom: "6px",
                    display: "block",
                  }}
                >
                  IMEI Search
                </label>
                <textarea
                  value={imeiSearchText}
                  onChange={(e) => setImeiSearchText(e.target.value)}
                  placeholder="Enter IMEIs (comma-separated or one per line)"
                  className="form-control"
                  style={{
                    width: "100%",
                    height: "60px",
                    resize: "none",
                    borderColor: "#ccc",
                    borderRadius: "6px",
                    padding: "8px",
                  }}
                />
              </div>

              {/* OR Divider */}
              <div
                className="or-divider"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  color: "#777",
                }}
              >
                OR
              </div>

              {/* Serial Search */}
              <div style={{ flex: "1 1 280px" }}>
                <label
                  style={{
                    fontWeight: "500",
                    marginBottom: "6px",
                    display: "block",
                  }}
                >
                  Serial Number Search
                </label>
                <textarea
                  value={serialSearchText}
                  onChange={(e) => setSerialSearchText(e.target.value)}
                  placeholder="Enter Serial Numbers (comma-separated or one per line)"
                  className="form-control"
                  style={{
                    width: "100%",
                    height: "60px",
                    resize: "none",
                    borderColor: "#ccc",
                    borderRadius: "6px",
                    padding: "8px",
                  }}
                />
              </div>
            </div>

            {/* Action Buttons */}
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                justifyContent: "center",
                gap: "12px",
              }}
            >
              <Button
                type="primary"
                onClick={handleImeiSearch}
                className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
                icon="🔍"
                style={{ padding: "6px 20px" }}
              >
                Search
              </Button>
              <Button
                onClick={handleResetSearch}
                style={{ padding: "6px 20px" }}
              >
                🔄 Reset
              </Button>
            </div>
          </Container>
          <Table
            title={"Sold Devices"}
            data={pageData?.rowData}
            columns={columns}
            option={{
              customToolbar: () => (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {/* <div
                    style={{
                      marginBottom: "16px",
                      fontSize: "18px",
                      marginLeft: "-58px",
                    }}
                  >
                    Total Row Count: <b>{rowCount || totalProducts}</b>
                  </div> */}
                </div>
              ),
              onTableChange: (action, tableState) => {
                if (action === "filterChange" || action === "search") {
                  setFilteredData(
                    tableState?.displayData?.map((row) => row?.data)
                  );
                }
              },
              selectableRows: "multiple",
              onRowSelectionChange: handleRowSelectionChange,
              customToolbarSelect: (
                selectedRows,
                displayData,
                setSelectedRows
              ) => (
                <div>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    onClick={() => SoldDevicesModelfun(selectedRepairRequest)}
                  >
                    Mark as Returned
                  </button>
                  &emsp;
                </div>
              ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default SoldDevicesTable;
