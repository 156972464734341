import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import AddCourierModal from "./AddCourierModal";
//import DeletePartnerModal from "./DeletePartnerModal";
import ModalService from "components/modal/ModalService";
import { getCouriers } from "./CouriersService";
import { format } from "date-fns";
import {Loader} from 'components'

const Couriers = () => {
  const [state, setState] = React.useState({
    gilad: true,
    jason: false,
    antoine: false,
  });
  const [couriers, setCouriers] = useState([]);
  const [loading, setLoading] = useState([]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const getAllCouriers = async () => {
  //  setLoading(true);
    try {
      const { msg, success, courier } = await getCouriers();
 
      if (success) {
       
        setCouriers(courier);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCouriers();
  }, []);

  const openModal = () => {
    ModalService.open(AddCourierModal, { refresh: () => getCouriers() });
  };
//   const openDelModal = (name, id) => {
//     ModalService.open(DeletePartnerModal, {
//       refresh: () => getCouriers(),
//       name,
//       id,
//     });
//   };
  const { gilad, jason, antoine } = state;
  return (
    <>
   
      {loading && <Loader/>}
      {!loading && (
        <div className="d-md-flex align-items-md-start">
          {/* <div
            className="sidebar sidebar-light mr-md-2 sidebar-component sidebar-component-left border-0 shadow-0 sidebar-expand-md"
            style={{ zIndex: 10 }}
          >
            <div className="sidebar-content">
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <span className="text-uppercase font-size-sm font-weight-semibold">
                    Filter
                  </span>
                  <div className="header-elements">
                    <div className="list-icons">
                      <a className="list-icons-item" data-action="collapse" />
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form action="#">
                    <div className="form-group form-group-feedback form-group-feedback-left">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Partner Name"
                      />
                      <div className="form-control-feedback">
                        <i className="icon-reading text-muted" />
                      </div>
                    </div>

                    <button type="submit" className="btn bg-blue btn-block">
                      <i className="icon-search4 font-size-base mr-2" />
                      Find partner
                    </button>
                  </form>
                </div>
              </div>
              <div className="card">
                <div className="card-header bg-transparent header-elements-inline">
                  <span className="text-uppercase font-size-sm font-weight-semibold">
                    Top Searches
                  </span>
                  <div className="header-elements">
                    <div className="list-icons">
                      <a className="list-icons-item" data-action="collapse" />
                    </div>
                  </div>
                </div>
                <form action="#">
                  <div className="card-body">
                    <div className="form-check">
                      <label className="form-check-label">
                        <div className="uniform-checker border-primary-600 text-primary-800">
                          <span className="checked">
                            <input
                              type="checkbox"
                              className="form-check-input-styled-primary"
                              checked={jason}
                              onChange={handleChange}
                              name="jason"
                              data-fouc
                            />
                          </span>
                        </div>
                        Nakuru
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <div className="uniform-checker border-primary-600 text-primary-800">
                          <span className="checked">
                            <input
                              type="checkbox"
                              className="form-check-input-styled-primary"
                              checked={gilad}
                              onChange={handleChange}
                              name="gilad"
                              data-fouc
                            />
                          </span>
                        </div>
                        Nairobi
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <div className="uniform-checker border-primary-600 text-primary-800">
                          <span className="checked">
                            <input
                              type="checkbox"
                              className="form-check-input-styled-primary"
                              checked={antoine}
                              onChange={handleChange}
                              name="antoine"
                              data-fouc
                            />
                          </span>
                        </div>
                        Mombasa
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          
          </div> */}
          <div className="flex-fill overflow-auto">
            <div className="card">
              <div className="card-header bg-white header-elements-inline">
                <h4 className="card-title">All Couriers</h4>
                <div className="header-elements">
                  <button
                    className="btn btn-sm badge bg-success"
                    onClick={openModal}
                  >
                    Add New Courier
                  </button>
                </div>
              </div>
              <table
              className="table text-nowrap table-customers dataTable no-footer dtr-column"
              id="DataTables_Table_0"
              role="grid"
              aria-describedby="DataTables_Table_0_info"
            >
              <thead>
                <tr role="row">
                  <th>Courier Name</th>
                 
                 
                  <th>Date Created</th>
                  <th>Status</th>
                
                  {/* <th className="text-center" style={{ width: 20 }}>
                    <i className="icon-arrow-down12" />
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {couriers.map(
                  ({
                    _id,
                    name,
                    
                    active,
                    createdAt,
                    
                  }) => (
                    <tr role="row" className="odd" key={_id}>
                      <td className="sorting_1">{name}</td>
           
                  
                      <td>{format(new Date(createdAt), "PP")}</td>
                      <td>
                        {active ? (
                          <span className="text-success">Active</span>
                        ) : (
                          <span className="text-warning">InActive</span>
                        )}
                      </td>
                    
                      <td className="text-center">
                        {/* <div className="list-icons">
                          <div className="list-icons-item dropdown">
                            <button
                              className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                              data-toggle="dropdown"
                            >
                              <i className="icon-menu7" />
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                              <a
                                href
                                className="dropdown-item"
                                onClick={() => viewCoupon(_id)}
                              >
                                <i className="icon-file-eye" /> View
                              </a>

                            </div>
                          </div> 
                        </div>*/}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            </div>
            <div className="d-flex justify-content-center pt-3 mb-3">
             
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Couriers;
