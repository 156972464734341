import React, { useState, useRef, useEffect } from "react";
import Modal from "components/modal/Modal";
import { useToastContext, ADD } from "context/ToastContext";
import { useHttp } from "hooks";
import ImageLogoBadili from "../../../../../assets/images/pdfLogo/badili-logo.jpg";
import html2pdf from "html2pdf.js";
import { format } from "date-fns";
import "../../../inventory/products/DownloadDeliveryNote.css";

const DownloadReturnNotModel = (props) => {
  const { toastDispatch } = useToastContext();
  const { error, loading, sendHttpRequest: getOrderNumber } = useHttp();
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: "",
    name: "",
  });
  const [OrderNumber, setOrderNumber] = useState("");

  const {
    error: errorWarehouses,
    loading: assigningWarehouse,
    sendHttpRequest: assignWarehouse,
  } = useHttp();
  // const country = process.env.REACT_APP_COUNTRY;
  const country = JSON.parse(
    sessionStorage.getItem("user")
  )?.country?.toLowerCase();
  const pdfRef = useRef(null);
  const handleWarehouseChange = (e) => {
    const selectedId = e.target.value;
    const selectedName =
      props.Warehouses.find((wh) => wh._id === selectedId)?.name || "";
    setSelectedWarehouse({ id: selectedId, name: selectedName });
  };
  useEffect(() => {
    getOrderNumber(
      `rnote_order_number/${process.env.REACT_APP_COUNTRY}`,
      "GET",
      null,
      ({ data: { note } }) => {
        setOrderNumber(note);
      }
    );
  }, []);
  const sendConsignmentFun = async () => {
    let loggedInUser = JSON.parse(sessionStorage.getItem("user"));
    assignWarehouse(
      `generate_return_note`,
      "PUT",
      {
        warehouseId: selectedWarehouse?.id,
        devices: props?.selectedRepairRequest,
        userId: loggedInUser?.id,
      },
      ({ msg }) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: msg,
            type: "success",
          },
        });
        props.refresh();
        props.close();
      }
    );
  };

  const handleDownloadPDF = () => {
    const randomNumber = Math.floor(Math.random() * 1000); // Adjust as needed
    const filename = `downloaded-badili-delivery-note-${randomNumber}.pdf`;
    const htmlContent = document.getElementById("htmlContentToConvert");
    const options = {
      margin: 5, // Adjust margin
      filename: filename,
      html2canvas: { scale: 1.5 }, // Adjust scale
      pagebreak: { mode: "avoid-all" },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    // html2pdf().from(htmlContent).set(options).save();
    if (htmlContent) {
      html2pdf().from(htmlContent).set(options).save();
      sendConsignmentFun();
    } else {
      console.error("HTML content not found. Unable to generate PDF.");
    }
  };

  return (
    <>
      <Modal
        close={props.close}
        onAccept={() => {
          if (!selectedWarehouse.id) {
            toastDispatch({
              type: ADD,
              payload: {
                content: "Please select a warehouse.",
                type: "danger",
              },
            });
            // Custom validation message
            return;
          }
          handleDownloadPDF();
        }}
        onReject={props.close}
        title={`Returns Pending Pickup`}
        form="downloadnoteform"
        AcceptText="Download"
        size="md"
        footerOntop={true}
        invoiceDown={true}
        invoiceDownContent={true}
      >
        <form onSubmit={(e) => e.preventDefault()} id="assign_Warehouse">
          <div
            className="form-group-container"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="form-group" style={{ width: "250px" }}>
              <label>
                Select Warehouse<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control form-control-sm"
                onChange={handleWarehouseChange}
                value={selectedWarehouse.id}
                name="via"
                defaultValue=""
                required
              >
                <option value="" selected disabled>
                  Select a Warehouse
                </option>
                {props?.Warehouses?.map(({ _id, name }) => (
                  <option value={_id} key={_id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
        <div id="htmlContentToConvert" className="scrllView" ref={pdfRef}>
          <div className="receipt-content ">
            <div className="container bootstrap snippets bootdey">
              <div className="row">
                <div className="col-md-12">
                  <div className="invoice-wrapper">
                    <div
                      className="intro"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src={ImageLogoBadili}
                        width={155}
                        height={65}
                        alt="Badili Logo"
                      />
                      <div style={{ marginLeft: "20px" }}>
                        {country === "ke" && (
                          <>
                            <p className="ft16">Kenya;</p>
                            <p className="ft16">P.O Box 8744-00100,</p>
                            <p className="ft16">Nairobi.</p>
                            <p className="ft16">Chiromo Court, Waiyaki Way</p>
                          </>
                        )}
                        {country === "ug" && (
                          <>
                            <p className="ft16">Badili Uganda SMC Limited</p>
                            <p className="ft16">
                              Office No 301 , Level 3- Kampala Boulevard
                            </p>
                            <p className="ft16">
                              Kampala Road - Kampala, Uganda.
                            </p>
                          </>
                        )}
                        {country === "tz" && (
                          <>
                            <p className="ft16">Badili Tanzania LTD</p>
                            <p className="ft16">Chole Plaza,</p>
                            <p className="ft16">
                              26 Chole Rd, Dar es Salaam -Tanzania
                            </p>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="payment-details">
                      <p
                        className="s3"
                        style={{
                          //  paddingTop: "1pt",
                          textAlign: "center",
                        }}
                      >
                        <h6 style={{ textDecoration: "underline" }}>
                          Return Note
                        </h6>
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <p
                            className="s2"
                            style={{
                              paddingLeft: "5pt",
                              textIndent: "0pt",
                              textAlign: "left",
                            }}
                          >
                            {props?.transfer?.deliveryId}
                          </p>
                        </div>
                        <div className="col-sm-6 delAddess">
                          <div>
                            {selectedWarehouse.id && (
                              <>
                                {" "}
                                <strong>Location</strong>:{" "}
                                {selectedWarehouse.name}
                              </>
                            )}
                            <br />
                            <strong>Address</strong>:{" "}
                            {
                              props?.selectedRepairRequest[0]?.warehouseObj
                                ?.address
                            }{" "}
                            <br />
                            {/* <strong>Customer Name</strong>:{" "}
                            {
                              props?.selectedRepairRequest[0]?.warehouseObj
                                ?.contactName
                            }{" "}
                            <br />
                            <strong>Customer Phone</strong>:{" "}
                            {
                              props?.selectedRepairRequest[0]?.warehouseObj
                                ?.contactPhone
                            }{" "}
                            <br /> */}
                            {/* {props?.selectedRepairRequest[0]?.warehouseObj?.contactEmail && (
                              <>
                                <strong>Customer Email</strong>:{" "}
                                {props?.selectedRepairRequest[0]?.warehouseObj?.contactEmail}
                                <br />
                              </>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-sm-6">
                        <strong>Order: </strong>
                        <br />
                        <p>{OrderNumber}</p>
                      </div>
                      <div className="col-sm-6">
                        <strong>Return Date: </strong>
                        <br />
                        <p>{format(new Date(), "dd-MMM-yyyy HH:mm a")}</p>
                      </div>
                    </div>
                    <div className="line-items">
                      <table
                        style={{
                          borderCollapse: "collapse",
                          marginLeft: "-7.2695pt",
                          marginBottom: "140px",
                        }}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr style={{ height: "21pt" }}>
                            <td
                              style={{
                                width: "229pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "104pt",
                                  paddingRight: "104pt",
                                  textIndent: "0pt",
                                  textAlign: "center",
                                }}
                              >
                                <strong>Product</strong>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "333pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "35pt",
                                  paddingRight: "35pt",
                                  textIndent: "0pt",
                                  textAlign: "center",
                                }}
                              >
                                <strong>Defects</strong>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "133pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "24pt",
                                  textIndent: "0pt",
                                  textAlign: "left",
                                }}
                              >
                                <strong>IMEI</strong>
                              </p>
                            </td>
                            <td
                              style={{
                                width: "74pt",
                                borderTopStyle: "solid",
                                borderTopWidth: "1pt",
                                borderTopColor: "#dde1e5",
                                borderLeftStyle: "solid",
                                borderLeftWidth: "1pt",
                                borderLeftColor: "#dde1e5",
                                borderBottomStyle: "solid",
                                borderBottomWidth: "2pt",
                                borderBottomColor: "#484f56",
                                borderRightStyle: "solid",
                                borderRightWidth: "1pt",
                                borderRightColor: "#dde1e5",
                              }}
                            >
                              <p
                                className="s3"
                                style={{
                                  paddingTop: "3pt",
                                  paddingLeft: "6pt",
                                  paddingRight: "13pt",
                                  textIndent: "0pt",
                                  textAlign: "center",
                                }}
                              >
                                <strong>Units</strong>
                              </p>
                            </td>
                          </tr>
                          {props?.selectedRepairRequest?.length
                            ? props?.selectedRepairRequest?.map((fdata) => (
                                <tr bgcolor="#F7F4F7">
                                  <td
                                    style={{
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                    <p
                                      className="s4"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "2pt",
                                        textIndent: "0pt",
                                        textAlign: "left",
                                      }}
                                    >
                                      {(() => {
                                        let formattedString = "";

                                        if (fdata?.model) {
                                          formattedString += `${fdata.model}`;
                                        }

                                        if (fdata?.variant) {
                                          if (formattedString) {
                                            formattedString += " - ";
                                          }
                                          formattedString += `${fdata.variant}`;
                                        }

                                        if (fdata?.color) {
                                          if (formattedString) {
                                            formattedString += " - ";
                                          }
                                          formattedString += `(${fdata.color})`;
                                        }

                                        return formattedString;
                                      })()}
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                 <p
  className="s4"
  style={{
    paddingTop: "3pt",
    paddingLeft: "2pt",
    textIndent: "0pt",
    textAlign: "left",
  }}
>
  {Array.isArray(fdata?.selloutReturnDefects) && fdata?.selloutReturnDefects.length > 0 ? (
    <ul>
      {fdata.selloutReturnDefects.map((defect, index) => (
        <li key={index}>{defect}</li>
      ))}
    </ul>
  ) : (
    <span>{''}</span>
  )}
</p>

                                  </td>
                                  <td
                                    style={{
                                      borderRightWidth: "1pt",
                                      borderRightColor: "#dde1e5",
                                    }}
                                  >
                                    <p
                                      className="s4"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "2pt",
                                        textIndent: "0pt",
                                        textAlign: "right",
                                      }}
                                    >
                                      {fdata?.imei}
                                    
                                    </p>
                                  </td>
                                  <td>
                                    <p
                                      className="s4"
                                      style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "2pt",
                                        textIndent: "0pt",
                                        textAlign: "center",
                                      }}
                                    >
                                      {"1"}
                                    </p>
                                  </td>
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <div style={{ fontSize: "9px", color: "#666" }}>
                        <p>
                          <strong>What is not covered in the Warranty?</strong>
                        </p>
                        <ul style={{ margin: 0, paddingLeft: "10px" }}>
                          <li>
                            <strong>Physical Damage:</strong> Damages caused by
                            drops, spills, or any other physical impact.
                          </li>
                          <li>
                            <strong>Water Damage:</strong> Damages caused by
                            water/liquid accidents.
                          </li>
                          <li>
                            <strong>
                              Unauthorized Repairs or Modifications:
                            </strong>{" "}
                            If you attempt to repair or modify your phone on
                            your own or through an unauthorized service, it will
                            void the warranty.
                          </li>
                          <li>
                            <strong>Software Modifications:</strong> Making
                            unauthorized changes to the phone's software, like
                            rooting or installing custom ROMs, will also void
                            the warranty.
                          </li>
                          <li>
                            <strong>Normal Wear and Tear:</strong> Warranties
                            usually don't cover the natural wear and tear that
                            occurs with regular use over time.
                          </li>
                          <li>
                            <strong>Loss or Theft:</strong> Warranty does not
                            cover loss or theft.
                          </li>
                        </ul>
                      </div>

                      <p
                        style={{
                          textAlign: "center",
                          borderTop: "2px solid #000000",
                          borderBottom: "2px solid #000000",
                          marginTop: 30,
                          paddingTop: 5,
                          paddingBottom: 10,
                        }}
                      >
                        {country === "ke"
                          ? "0725626262"
                          : country === "ug"
                          ? "+256200906262"
                          : country === "tz"
                          ? "+255 672626262 / +255 694626262"
                          : ""}
                        &nbsp;&nbsp;
                        <a
                          href="mailto:accounts@badili.africa"
                          className="a"
                          target="_blank"
                        >
                          accounts@badili.africa
                        </a>
                        &nbsp;&nbsp;
                        {country === "ke"
                          ? "https://badili.ke/"
                          : country === "ug"
                          ? "https://badili.ug/"
                          : country === "tz"
                          ? "https://badili.co.tz/"
                          : ""}
                      </p>
                      {/* <p style={{ textAlign: "center" }}>Page: 1 of 1</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DownloadReturnNotModel;
