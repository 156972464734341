import React, { useState, useEffect } from "react";
import { Loader, Table } from "components";
import { useHttp } from "hooks";
import { format } from "date-fns";
import OverSaleSelloutModal from "./OverSaleSelloutModal";
import ModalService from "components/modal/ModalService";
import { Button, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
function HistorSellOutHistory() {
  const [SellOutHistory, setSellOutHistory] = useState([]);
  const { error, loading, sendHttpRequest: getStoress } = useHttp();
  const [filteredData, setFilteredData] = useState([]);
  let selectedDataBulk = "";
  const getAllStoress = () => {
    let tempData = [];
    getStoress(
      "mngt_all_sellouts",
      "GET",
      null,
      ({ success, data: { requests } }) => {
        if (success) {
          requests?.forEach((el) => {
            tempData.push({
              active: el?.active ? "Active" : "InActive",
              country: el?.country?.toUpperCase() || "-",
              assignedColor: el?.assignedColor[0]?.name || "-",
              consignmentDate: el?.consignmentDate,
              current_status: el?.current_status[0]?.name || "-",
              warehouse: el?.warehouse[0]?.name || "-",
              source: el?.source?.toUpperCase() || "-",
              sold_via: el?.sold_via?.toUpperCase() || "-",
              soldDate: el?.soldDate,
              variant: el.variant?.length
                ? `${el.variant[0]?.ram}GB/${el.variant[0]?.storage}GB`
                : "-",
              rts: el?.rts ? "Yes" : "No",
              registeredWarranty: el?.registeredWarranty ? "Yes" : "No",
              serialNumber: el?.serialNumber || "-",
              imei: el?.imei || "-",
              factorySerialNumber:el?.factorySerialNumber || "-",
              modelName: el?.model?.modelName || "-",
              createdAt: el?.createdAt,
              _id: el?._id,
              invoicetotalPrice: el?.invoice?.totalPrice || "-",
              sales_agent: `${el?.sales_agent?.fname || ""} ${
                el?.sales_agent?.lname || ""
              }`.trim(),
              invoiceNumber: el?.invoice?.invoiceNumber || "-",
              contactEmail: el?.invoice?.contactEmail || "-",
              contactName: el?.invoice?.contactName || "-",
              contactPhone: el?.invoice?.contactPhone || "-",
            });
          });
          setSellOutHistory(tempData);
        } else {
          setSellOutHistory([]);
          console.error("Failed to fetch stores data");
        }
      }
    );
  };

  useEffect(() => {
    getAllStoress();
  }, []);
  const openOverSaleSellModal = (saleflag, data) => {
    ModalService.open(OverSaleSelloutModal, {
      saleflag: saleflag,
      selectedBulk: selectedDataBulk,
      refresh: () => getAllStoress(), // Corrected function passing
    });
  };
  // Handle Bulk select
  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected?.map(
      ({ dataIndex }) => SellOutHistory[dataIndex]
    );
    let tempObjData = [];
    selectedData?.map((data, index) => {

      tempObjData.push({
        _id: data?._id,
      });
    });
    selectedDataBulk = tempObjData;
  };
  const columns = [
    {
      label: "Model Name",
      name: "modelName",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Variant",
      name: "variant",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Factory Serial Number",
      name: "factorySerialNumber",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          {
            return value ? value : "-";
          }
        },
      },
    },
    {
      label: "Assigned Color",
      name: "assignedColor",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Warehouse",
      name: "warehouse",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Registered Warranty",
      name: "registeredWarranty",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value) => (
          <span className={value === "Yes" ? "text-success" : "text-danger"}>
            {value}
          </span>
        ),
      },
    },
    {
      label: "Sellout Date",
      name: "soldDate",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value) =>
          value ? format(new Date(value), "yyyy-MM-dd") : "-",
      },
    },
    {
      label: "Consignment Date",
      name: "consignmentDate",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value) =>
          value ? format(new Date(value), "yyyy-MM-dd") : "-",
      },
    },
    {
      label: "Sold Via",
      name: "sold_via",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "RTS",
      name: "rts",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value) => (
          <span className={value === "Yes" ? "text-success" : "text-danger"}>
            {value}
          </span>
        ),
      },
    },
    {
      label: "Current Status",
      name: "current_status",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Country",
      name: "country",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Invoice Number",
      name: "invoiceNumber",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Invoice Total Price",
      name: "invoicetotalPrice",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Contact Name",
      name: "contactName",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Contact Email",
      name: "contactEmail",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Contact Phone",
      name: "contactPhone",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
      },
    },
    {
      label: "Created At",
      name: "createdAt",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value) =>
          value ? format(new Date(value), "yyyy-MM-dd") : "-",
      },
    },
    {
      label: "Sale Agent Name",
      name: "sales_agent",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
      },
    },
  ];

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              gap: "2rem",
            }}
          >
            <Table
              columns={columns}
              data={SellOutHistory}
              title="SellOut History"
              option={{
                onTableChange: (action, tableState) => {
                  if (action === "filterChange" || action === "search") {
                    setFilteredData(
                      tableState?.displayData?.map((row) => row?.data)
                    );
                  }
                },
                selectableRows: "multiple",
                onRowSelectionChange: handleRowSelectionChange,
                customToolbarSelect: (
                  selectedRows,
                  displayData,
                  setSelectedRows
                ) => {
                  const actions = [
                    {
                      name: "Overturn Sale",
                      onClick: () => openOverSaleSellModal("Overturn Sale"),
                    },
                    {
                      name: "⁠Return From Sellout",
                      onClick: () =>
                        openOverSaleSellModal("⁠Return From Sellout"),
                    },
                  ];
                  const menu = (
                    <Menu>
                      {actions.map((action, index) => (
                        <Menu.Item key={index} onClick={action.onClick}>
                          {action.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  );
                  return (
                    <div>
                      <Dropdown overlay={menu}>
                        <Button>
                          <Space>
                            Actions
                            <DownOutlined />
                          </Space>
                        </Button>
                      </Dropdown>
                    </div>
                  );
                },
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default HistorSellOutHistory;
