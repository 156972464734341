import React, { useState } from "react";
import Modal from "components/modal/Modal";
import { useHttp } from "hooks";

const UpdateDealerModal = (props) => {
  const {
    error,
    loading: savingDealer,
    sendHttpRequest: updateLocation,
  } = useHttp();
  const [userLatiLoc, setuserLatiLoc] = useState(null);
  const [userLongLoc, setuserLongLoc] = useState(null);
  const [DealerBranch, setDealerBranch] = useState({
    latitude: props?.latitude,
    longitude: props?.longitude,
  });

  const EditNewLocationDealer = async () => {
    updateLocation(
      `update_dealer/${props.id}`,
      "PUT",
      {
        lat: userLatiLoc,
        long: userLongLoc,
      },
      () => {
        props.refresh();
        props.close();
      }
    );
  };
  // const onChange = (e) => {
  //   const { name, value } = e.target;
  //   setDealerBranch({ ...DealerBranch, [name]: value });
  // };
  const getGPSCoordinates = (e) => {
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    function success(pos) {
      var crd = pos.coords;
      setuserLongLoc(crd.longitude);
      setuserLatiLoc(crd.latitude);
    }

    function error(err) {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
    // navigator.geolocation.getCurrentPosition((position) => {
    //   const { latitude, longitude } = position.coords;
    //   console.log({ latitude, longitude });
    //   setuserLongLoc(longitude);
    //   setuserLatiLoc(latitude);
    // });
  };
  return (
    <Modal
      close={props.close}
      onAccept={() => EditNewLocationDealer()}
      title="Update Dealer Location"
      loading={savingDealer}
      size="sm"
    >
      <form>
        <div className="form-group">
          <button
            type="button"
            onClick={() => getGPSCoordinates()}
            className="btn btn-primary"
          >
            Get GPS Coordinates
          </button>
        </div>
        <div className="form-group">
          <p>
            <b>Dealer Name </b>: <span>{props.name}</span>
          </p>
          <p>
            <b>Latitude </b>: <span>{userLatiLoc}</span>
          </p>
          <p>
            <b>Longitude </b>: <span>{userLongLoc}</span>
          </p>
        </div>

        {/* <div className="form-group">
          <label>Latitude : </label>
          <input
            name="latitude"
            type="text"
            placeholder=""
            value={userLatiLoc}
            readOnly
            onChange={onChange}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Longitude : </label>
          <input
            name="longitude"
            type="text"
            placeholder=""
            required
            readOnly
            value={userLongLoc}
            onChange={onChange}
            className="form-control"
          />
        </div> */}
      </form>
    </Modal>
  );
};

export default UpdateDealerModal;
