import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loader, Table } from "components";
import { format } from "date-fns";
import MarkAsRTSModal from "./MarkAsRTSModal";
import BackToSoldModal from "./BackToSoldModal";
import ModalService from "components/modal/ModalService";
import AssignToTeamModal from "./AssignToTeamModal";
import TransferConsignmentModel from "./TransferConsignmentModel";
import ReturnToRTSModel from "./ReturnToRTSModel";
import DownloadReturnNotModel from "./DownloadReturnNotModel";
import ReturnToWIPModel from "./ReturnToWIPModel";
import ReturnToOutboundModel from "./ReturnToOutboundModel";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { useHttp } from "hooks";
import { Button, Dropdown, Menu, Space } from "antd";
import InputLabel from "@mui/material/InputLabel";
import { DownOutlined } from "@ant-design/icons";
import {
  Container,
  Grid,
  Select,
  MenuItem,
  FormControl,
  NativeSelect,
} from "@mui/material";

const ListProducts = () => {
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const [newRowData, setNewRowData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRepairRequest, setSelectedRepairRequest] = useState([]);
  const [Warehouses, setWarehouses] = useState([]);
  const { error, loading, sendHttpRequest: getWarehouses } = useHttp();
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getInventory,
  } = useHttp();
  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getInven();
    getWarehouses(
      "get_all_warehouses",
      "GET",
      null,
      ({ data: { warehouse } }) => setWarehouses(warehouse)
    );
  }, []);
  useEffect(() => {
    // Update the row count whenever the data changes
    setRowCount(pageData?.rowData?.length);
  }, [pageData?.rowData]);

  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);

  // const openTransferConsignmentModel = (repairData) => {
  //   ModalService.open(TransferConsignmentModel, {
  //     refresh: () => getInven(),
  //     selectedRepairRequest: repairData,
  //     Warehouses: Warehouses,
  //   });
  // };
  const openReturnToINBOUND = (repairData) => {
    ModalService.open(ReturnToWIPModel, {
      refresh: () => getInven(),
      selectedRepairRequest: repairData,
      // Warehouses: Warehouses,
    });
  };
  const openPushToOutboundModel = (repairData) => {
    ModalService.open(ReturnToOutboundModel, {
      refresh: () => getInven(),
      selectedRepairRequest: repairData,
      // Warehouses: Warehouses,
    });
  };
  const openDownloadReturnNote = (repairData) => {
    ModalService.open(DownloadReturnNotModel, {
      refresh: () => getInven(),
      selectedRepairRequest: repairData,
      Warehouses: Warehouses,
    });
  };
  // const openReturnToRTSModel = (repairData) => {
  //   ModalService.open(ReturnToRTSModel, {
  //     refresh: () => getInven(),
  //     selectedRepairRequest: repairData,
  //     // Warehouses: Warehouses,
  //   });
  // };
  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected?.map(
      ({ dataIndex }) => pageData?.rowData[dataIndex]
    );
    let tempObjData = [];
    selectedData?.map((data, _) => {
      tempObjData.push({
        device_id: data?._id,
        imei: data?.imei,
        model: data?.model,
        variant: data?.variant,
        color: data?.color,
        warehouseObj: data?.warehouseObj,
        selloutReturnDefects:data?.selloutReturnDefects
      });
    });
    setSelectedRepairRequest(tempObjData);
  };

  // const openAssignToTeamModel = (inventoryID, status) => {
  //   ModalService.open(AssignToTeamModal, {
  //     refresh: getInven,
  //     status: status,
  //     inventoryID: inventoryID,
  //   });
  // };

  // const openmarkAsRTSModal = (name, inventoryId) => {
  //   ModalService.open(MarkAsRTSModal, {
  //     refresh: getInven,
  //     name: name,
  //     inventoryId: inventoryId,
  //   });
  // };
  const openBackToSoldModal = (name, inventoryId) => {
    ModalService.open(BackToSoldModal, {
      refresh: getInven,
      name: name,
      inventoryId: inventoryId,
    });
  };
  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getInven();
    getWarehouses(
      "get_all_warehouses",
      "GET",
      null,
      ({ data: { warehouse } }) => setWarehouses(warehouse)
    );
  }, []);

  const handlInventoryData = ({ data: { requests } }) => {
    setNewRowData([]);
    let tempData = [];
    requests?.map((el) => {
      return tempData.push({
        _id: el?._id,
        date: format(new Date(el?.createdAt), "dd MMM yyyy"),
        model: el?.model?.modelName,
        model_id: el?.model?._id,
        variant:
          el?.variant[0]?.ram !== undefined
            ? `${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType}`
            : "",
        imei: el?.imei && el?.imei !== undefined ? el?.imei : "-",
        serial_number:
          el?.serialNumber && el?.serialNumber !== undefined
            ? el?.serialNumber
            : "-",
        source:
          el?.source !== undefined && el?.source
            ? el?.source?.toUpperCase()
            : "-",
        assigned_store: el?.stores !== undefined && el?.stores?.name,
        rrp: el?.variant[0]?.discounted,
        wsp: el?.variant[0]?.wsp,
        color: el?.colorSet ? el.assignedColor[0]?.name : "",
        selloutReturnRemarks: el?.selloutReturnRemarks || "-",
        selloutReturnDefects: el?.selloutReturnDefects || "-",
        sold: el?.sold,
        rts: el?.rts,
        warehouse: el?.warehouse?.name !== undefined && el?.warehouse?.name,
        action: [
          el?._id,
          el?.sold,
          el?.model?.modelName,
          el?.model?._id,
          el,
          el?.colorSet ? el.assignedColor[0]?.name : "",
        ],
        // status: el?.current_status[0]?.name?.toUpperCase(),
      });
    });
    setNewRowData(requests);
    setPageData({
      isLoading: false,
      rowData: tempData,
      totalProducts: requests.length,
    });
  };

  const getInven = async () => {
    getInventory("get_returned_devices", "GET", null, handlInventoryData);
  };

  const listInv = (e) => {
    setPageData({
      isLoading: false,
      rowData: [],
      totalProducts: 0,
    });
    const newtempData = newRowData?.reduce((acc, el) => {
      if (el?.warehouse?.name === e.target.value || e.target.value === "All") {
        acc.push({
          _id: el?._id,
          date: format(new Date(el?.createdAt), "dd MMM yyyy"),
          model: el?.model?.modelName,
          model_id: el?.model?._id,
          variant:
            el?.variant[0]?.ram !== undefined
              ? `${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType}`
              : "",
          imei: el?.imei && el?.imei !== undefined ? el?.imei : "-",
          factorySerialNumber: el?.factorySerialNumber && el?.factorySerialNumber !== undefined ? el?.factorySerialNumber : "-",
          serial_number:
            el?.serialNumber && el?.serialNumber !== undefined
              ? el?.serialNumber
              : "-",
          source:
            el?.source !== undefined && el?.source
              ? el?.source?.toUpperCase()
              : "-",
          assigned_store: el?.stores !== undefined && el?.stores?.name,
          rrp: el?.variant[0]?.discounted,
          wsp: el?.variant[0]?.wsp,
          color: el?.colorSet ? el.assignedColor[0]?.name : "",
          sold: el?.sold,
          selloutReturnRemarks: el?.selloutReturnRemarks || "-",
          selloutReturnDefects: el?.selloutReturnDefects || "-",
          rts: el?.rts,
          warehouse: el?.warehouse?.name !== undefined && el?.warehouse?.name,
          action: [
            el?._id,
            el?.sold,
            el?.model?.modelName,
            el?.model?._id,
            el,
            el?.colorSet ? el.assignedColor[0]?.name : "",
          ],
          // status: el?.current_status[0]?.name?.toUpperCase(),
        });
      }
      return acc;
    }, []);
    setPageData({
      isLoading: false,
      rowData: newtempData,
      totalProducts: newtempData.length,
    });
  };

  const columns = [
    {
      label: "DATE",
      name: "date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <h6 className="mb-0">
                {format(new Date(value), "dd")} {format(new Date(value), "MMM")}
              </h6>
              <div className="font-size-sm text-muted line-height-1">
                {format(new Date(value), "yyyy")}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "model_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Product Name",
      name: "model",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                <Link
                  style={{ fontWeight: 600 }}
                  to={`/inventory/product-configuration/${tableMeta.rowData[1]}`}
                  className="letter-icon-title"
                >
                  {value}
                </Link>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "VARIANT",
      name: "variant",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="font-weight-semibold">{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Factory Serial Number",
      name: "factorySerialNumber",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          {
            return value ? value : "-";
          }
        },
      },
    },
    {
      label: "SERIAL NUMBER",
      name: "serial_number",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "SOURCE",
      name: "source",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "WAREHOUSE",
      name: "warehouse",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <b>{value ? value : "-"}</b>;
        },
      },
    },

    {
      label: "RRP",
      name: "rrp",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            thousandCommaSeparator(value)
          ) : (
            <span className="text-warning text-center">Price not set</span>
          );
        },
      },
    },
    {
      label: "WSP",
      name: "wsp",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            thousandCommaSeparator(value)
          ) : (
            <span className="text-warning text-center">Price not set</span>
          );
        },
      },
    },
    {
      label: "Color",
      name: "color",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : <p>Color not set</p>}</>;
        },
      },
    },
    {
      label: "Sellout Return Defects",
      name: "selloutReturnDefects",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "left" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          // Ensure value is an array
          if (Array.isArray(value) && value.length > 0) {
            return (
              <ul>
                {value.map((defect, index) => (
                  <li key={index}>{defect}</li>
                ))}
              </ul>
            );
          } else {
            return <p>No defects reported.</p>;
          }
        },
      },
    },
    {
      label: "Sellout Return Remarks",
      name: "selloutReturnRemarks",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Available",
      name: "sold",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v.length !== 0) {
              return !v[0] ? "Yes" : "No";
            }
            return false;
          },
        },
        filterOptions: {
          names: [],
          logic(status, filter) {
            if (filter.length > 0) {
              return status !== filter[0];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                Available
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
              >
                <MenuItem value={false}>{"Yes"}</MenuItem>
                <MenuItem value={true}>{"No"}</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <span className="text-warning text-center">No</span>
              ) : (
                <span className="text-success text-center">Yes</span>
              )}
            </>
          );
        },
      },
    },
    {
      label: "RTS",
      name: "rts",
      options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
          render: (v) => {
            if (v.length !== 0) {
              return v[0] ? "Yes" : "No";
            }
            return false;
          },
        },
        filterOptions: {
          names: [],
          logic(status, filter) {
            if (filter.length > 0) {
              return status !== filter[0];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                RTS
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                onChange={(event) => {
                  filterList[index][0] = event.target.value;
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
              >
                <MenuItem value={true}>{"Yes"}</MenuItem>
                <MenuItem value={false}>{"No"}</MenuItem>
              </Select>
            </FormControl>
          ),
        },
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value ? (
                <span className="text-success text-center">Yes</span>
              ) : (
                <span className="text-danger text-center">No</span>
              )}
            </>
          );
        },
      },
    },
    // {
    //   label: "Current Status",
    //   name: "status",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //   },
    // },
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <div className="list-icons-item dropdown">
                  <button
                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                    data-toggle="dropdown"
                  >
                    <i className="icon-menu7" />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    {/* <>
                      <a
                        href
                        className="dropdown-item"
                        onClick={() =>
                          openAssignToTeamModel(value?.at(0), "WIP")
                        }
                      >
                        <i className="icon-pushpin" /> Push to WIP
                      </a>
                    </>

                    <>
                      <a
                        href
                        className="dropdown-item"
                        onClick={() => {
                          openmarkAsRTSModal(value?.at(2), value?.at(0));
                        }}
                      >
                        <i className="icon-pushpin" /> Push to RTS
                      </a>
                    </> */}
                    <>
                      <a
                        href
                        className="dropdown-item"
                        onClick={() => {
                          openBackToSoldModal(value?.at(2), value?.at(0));
                        }}
                      >
                        <i className="icon-pushpin" /> Push Back to Sold
                      </a>
                    </>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      {loadingInventory ? (
        <Loader />
      ) : (
        <>
          <Container
            maxWidth="md"
            sx={{
              backgroundColor: "#f9f9f9",
              padding: "12px 16px",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <h4 style={{ margin: 0, fontSize: "16px", color: "#495057" }}>
              📦 Device Returns
            </h4>

            <FormControl size="small" sx={{ minWidth: 180 }}>
              <InputLabel>Warehouse</InputLabel>
              <Select
                defaultValue="All"
                onChange={(e) => listInv(e)}
                label="Warehouse"
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  ".MuiOutlinedInput-notchedOutline": { borderColor: "#ddd" },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#aaa",
                  },
                }}
              >
                <MenuItem value="All">All Warehouses</MenuItem>
                {Warehouses?.map(({ _id, name }) => (
                  <MenuItem key={_id} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Container>

          <div
            className="card"
            style={{
              padding: "8px",
              boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <div
              className="table-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "12px",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                RTS - Total Rows: <b>{rowCount}</b>
              </div>
            </div>
            <Table
              title={"Device Returns"}
              data={pageData.rowData}
              columns={columns}
              option={{
                // customToolbar: () => (
                //   <div style={{ fontSize: "14px", fontWeight: 500 }}>
                //     Total Rows: <b>{rowCount}</b>
                //   </div>
                // ),
                onTableChange: (action, tableState) => {
                  if (action === "filterChange" || action === "search") {
                    setFilteredData(
                      tableState.displayData.map((row) => row.data)
                    );
                  }
                },
                selectableRows: "multiple",
                onRowSelectionChange: handleRowSelectionChange,
                customToolbarSelect: (
                  selectedRows,
                  displayData,
                  setSelectedRows
                ) => {
                  const actions = [
                    {
                      name: "Generate Return Note",
                      onClick: () =>
                        openDownloadReturnNote(selectedRepairRequest),
                    },
                  ];

                  const menu = (
                    <Menu>
                      {actions?.map((action, index) => (
                        <Menu.Item key={index} onClick={action.onClick}>
                          {action.name}
                        </Menu.Item>
                      ))}
                    </Menu>
                  );
                  return (
                    <Dropdown overlay={menu}>
                      <Button>
                        <Space>
                          Actions
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  );
                },
              }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ListProducts;
