import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loader, Table } from "components";
import { format } from "date-fns";
import MediumOfSaleModal from "./MediumOfSaleModal";
import SetAsUnsoldModal from "./SetAsUnsoldModal";
import ModalService from "components/modal/ModalService";
import StoreModal from "./StoreModal";
import DeleteInvModal from "./DeleteInvModal";
import BulkDeleteInvModal from "./BulkDeleteInvModal";
import BulkUpdateInvModal from "./BulkUpdateInvModal";
import UpdateInvModal from "./UpdateInvModal";
import TransferConsignmentModel from "./TransferConsignmentModel";
import CountryTransferConsignmentModel from "./CountryTransferConsignmentModel";
import EditLocationModel from "./EditLocationModel";
import AddColorModal from "./AddColorModal";
import { thousandCommaSeparator } from "helpers/thousandCommaSeparator";
import { useHttp } from "hooks";
import { Button, Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import {
  Container,
  Grid,
  Select,
  MenuItem,
  FormControl,
  NativeSelect,
} from "@mui/material";
import ReturnToWIPModel from "app/modules/sales/return/products/ReturnToWIPModel";

const ListProducts = () => {
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalPages: 0,
    totalProducts: 0,
  });
  const [newRowData, setNewRowData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowProductsCount, setProductsRowCount] = useState(0);
  const [rowCountOnlineCount, setRowCountOnlineCount] = useState(0);
  const [rowCountImportantCount, setRowCountImportantCount] = useState(0);
  const [rowRTSCount, setRowRTSCount] = useState(0);
  const [rowCountStockCount, setRowCountStockCount] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRepairRequest, setSelectedRepairRequest] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [Warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const { error, loading, sendHttpRequest: getWarehouses } = useHttp();
  const [imeiSearchText, setImeiSearchText] = useState("");
  const [serialSearchText, setSerialSearchText] = useState("");
  const [searchedImeis, setSearchedImeis] = useState([]);
  const [LocationData, setLocationData] = useState([]);
  const {
    wareError,
    loading: wareLoading,
    sendHttpRequest: LocationGetAll,
  } = useHttp();
  const {
    error: errorInventory,
    loading: loadingInventory,
    sendHttpRequest: getInventory,
  } = useHttp();
  useEffect(() => {
    // Update the row count whenever the data changes
    setRowCount(pageData?.rowData?.length);
    // setProductsRowCount(pageData?.rowData?.length);
    // setRowCountOnlineCount(
    //   pageData?.rowData?.filter(
    //     (product) => product?.source?.toLowerCase() === "online"
    //   ).length
    // );
    // setRowCountImportantCount(
    //   pageData?.rowData?.filter(
    //     (product) => product?.source?.toLowerCase() === "imported"
    //   ).length
    // );
    // setRowCountStockCount(
    //   pageData?.rowData?.filter(
    //     (product) => product?.source?.toLowerCase() === "stock"
    //   ).length
    // );
    // setRowRTSCount(
    //   pageData?.rowData?.filter(
    //     (product) => product?.status.toLowerCase() === "ready to sell"
    //   ).length
    // );
  }, [pageData?.rowData]);
  useEffect(() => {
    setRowCount(filteredData?.length);
  }, [filteredData]);
  // const openTransferConsignmentModel = (repairData) => {
  //   ModalService.open(TransferConsignmentModel, {
  //     refresh: () => getInven(),
  //     selectedRepairRequest: repairData,
  //     Warehouses: Warehouses,
  //   });
  // };
  const openTransferCountryTransfer = (repairData) => {
    ModalService.open(CountryTransferConsignmentModel, {
      refresh: () => getInven(),
      selectedRepairRequest: repairData,
      Warehouses: Warehouses,
    });
  };
  const openEditLocationModel = (repairData) => {
    ModalService.open(EditLocationModel, {
      refresh: () => getInven(),
      selectedRepairRequest: repairData,
      LocationData,
    });
  };
  const handleRowSelectionChange = (currentRowsSelected, allRowsSelected) => {
    const selectedData = allRowsSelected?.map(
      ({ dataIndex }) => pageData?.rowData[dataIndex]
    );
    let tempObjData = [];
    selectedData?.map((data, _) => {
      tempObjData.push({
        details_id: data?._id,
      });
    });
    setSelectedRepairRequest(tempObjData);

    setSelectedRowsData(selectedData);
  };
  const wareHandleData = ({ data: { locations } }) => {
    const formattedLocations = locations?.map((sdata) => ({
      value: sdata?._id,
      label: `WING(${sdata?.wing})-(SECTOR) ${sdata?.sector}-${sdata?.location}`, //WING-A-RTS-300B
    }));
    setLocationData(formattedLocations);
  };

  const openSoldModal = (id) => {
    ModalService.open(MediumOfSaleModal, {
      id: id,
      refresh: () => getInven(),
    });
  };
  const openBulkDelete = (repairData) => {
    ModalService.open(BulkDeleteInvModal, {
      selectedRepairRequest: repairData,
      refresh: () => getInven(),
    });
  };
  const openReturnToWIPModel = (repairData) => {
    ModalService.open(ReturnToWIPModel, {
      refresh: () => getInven(),
      selectedRepairRequest: repairData,
      // Warehouses: Warehouses,
    });
  };
  const openBulkUpdate = (repairData) => {
    ModalService.open(BulkUpdateInvModal, {
      selectedRepairRequest: repairData,
      refresh: () => getInven(),
    });
  };
  const opensetAsUnsoldModal = (id, name) => {
    ModalService.open(SetAsUnsoldModal, {
      id: id,
      name,
      refresh: getInven,
    });
  };
  const openStoreModal = (el) => {
    ModalService.open(StoreModal, {
      device: el,
      refresh: getInven,
    });
  };
  // const openDeleteInvModel = (id, el) => {
  //   ModalService.open(DeleteInvModal, {
  //     id: id,
  //     invData: el,
  //     refresh: getInven,
  //   });
  // };
  const openUpdateInvModel = (id, el) => {
    ModalService.open(UpdateInvModal, {
      id: id,
      invData: el,
      refresh: getInven,
    });
  };
  const openAddColorModal = (id, InventoryId, title) => {
    ModalService.open(AddColorModal, {
      id,
      InventoryId,
      title,
      refresh: getInven,
    });
  };
  useEffect(() => {
    setPageData((prevState) => ({
      ...prevState,
      rowData: [],
      isLoading: true,
    }));
    getInven();
    getWarehouses(
      "get_all_warehouses",
      "GET",
      null,
      ({ data: { warehouse } }) => setWarehouses(warehouse)
    );
    LocationGetAll(`get_storages`, "GET", null, wareHandleData);
  }, []);

  const handlInventoryData = ({ data: { requests } }) => {
    setNewRowData([]);
    let tempData = [];
    requests?.map((el) => {
      return tempData.push({
        _id: el?._id,
        date: format(new Date(el?.createdAt), "dd MMM yyyy"),
        model: el?.model?.modelName,
        model_id: el?.model?._id,
        variant:
          el?.variant[0]?.ram !== undefined
            ? `${el?.variant[0]?.ram}GB/${el?.variant[0]?.storage}${el?.variant[0]?.storageType}`
            : "",
        imei: el?.imei && el?.imei !== undefined ? el?.imei : "-",
        factorySerialNumber:
          el?.factorySerialNumber && el?.factorySerialNumber !== undefined
            ? el?.factorySerialNumber
            : "-",
        serial_number:
          el?.serialNumber && el?.serialNumber !== undefined
            ? el?.serialNumber
            : "-",
        // source:
        //   el?.source !== undefined && el?.source
        //     ? el?.source?.toUpperCase()
        //     : "-",
        assigned_store: el?.stores !== undefined && el?.stores?.name,
        rrp: el?.variant[0]?.discounted && el?.variant[0]?.discounted,
        wsp: el?.variant[0]?.wsp && el?.variant[0]?.wsp,
        color: el?.colorSet ? el.assignedColor[0]?.name : "",
        sold: el?.sold,
        rts: el?.rts,
        storage: el.storage
          ? `WING(${el?.storage?.wing})-(SECTOR) ${el?.storage?.sector}-${el?.storage?.location}`
          : "-",
        warehouse: el?.warehouse?.name !== undefined && el?.warehouse?.name,
        unitPrice: el?.unitPrice ? thousandCommaSeparator(el?.unitPrice) : "-",
        action: [
          el?._id,
          el?.sold,
          el?.model?.modelName,
          el?.model?._id,
          el,
          el?.colorSet ? el.assignedColor[0]?.name : "",
        ],
        // status: el?.current_status[0]?.name?.toUpperCase(),
      });
    });
    setNewRowData(requests);
    setPageData({
      isLoading: false,
      rowData: tempData,
      totalProducts: requests.length,
    });
  };

  const getInven = async () => {
    getInventory("get_inventory", "GET", null, handlInventoryData);
  };

  const columns = [
    {
      label: "DATE",
      name: "date",
      options: {
        sort: false,
        filter: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <h6 className="mb-0">
                {format(new Date(value), "dd")} {format(new Date(value), "MMM")}
              </h6>
              <div className="font-size-sm text-muted line-height-1">
                {format(new Date(value), "yyyy")}
              </div>
            </>
          );
        },
      },
    },
    {
      name: "model_id",
      options: {
        filter: false,
        sort: false,
        display: false,
        print: false,
        download: false,
      },
    },
    {
      label: "Product Name",
      name: "model",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>
                <Link
                  style={{ fontWeight: 600 }}
                  to={`/inventory/product-configuration/${tableMeta.rowData[1]}`}
                  className="letter-icon-title"
                >
                  {value}
                </Link>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "VARIANT",
      name: "variant",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="font-weight-semibold">{value}</div>
            </>
          );
        },
      },
    },
    {
      label: "IMEI",
      name: "imei",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    {
      label: "Factory Serial Number",
      name: "factorySerialNumber",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          {
            return value ? value : "-";
          }
        },
      },
    },
    {
      label: "SERIAL NUMBER",
      name: "serial_number",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
      },
    },
    // {
    //   label: "SOURCE",
    //   name: "source",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "center" },
    //     }),
    //   },
    // },
    // {
    //   label: "WAREHOUSE",
    //   name: "warehouse",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "center" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <>{value ? value : "-"}</>;
    //     },
    //   },
    // },
    // {
    //   label: "ASSIGNED STORE",
    //   name: "assigned_store",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           {value ? value : <span className="text-warning">Unassigned</span>}
    //         </>
    //       );
    //     },
    //   },
    // },
    {
      label: "Purchase Price",
      name: "unitPrice",
      options: {
        filter: true,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      label: "RRP",
      name: "rrp",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            thousandCommaSeparator(value)
          ) : (
            <span className="text-warning text-center">Price not set</span>
          );
        },
      },
    },
    {
      label: "WSP",
      name: "wsp",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? (
            thousandCommaSeparator(value)
          ) : (
            <span className="text-warning text-center">Price not set</span>
          );
        },
      },
    },
    {
      label: "Color",
      name: "color",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { whiteSpace: "pre", textAlign: "center" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{value ? value : <p>Color not set</p>}</>;
        },
      },
    },
    // {
    //   label: "Available",
    //   name: "sold",
    //   options: {
    //     filter: true,
    //     filterType: "custom",
    //     customFilterListOptions: {
    //       render: (v) => {
    //         if (v.length !== 0) {
    //           return !v[0] ? "Yes" : "No";
    //         }
    //         return false;
    //       },
    //     },
    //     filterOptions: {
    //       names: [],
    //       logic(status, filter) {
    //         if (filter.length > 0) {
    //           return status !== filter[0];
    //         }
    //         return false;
    //       },
    //       display: (filterList, onChange, index, column) => (
    //         <FormControl variant="standard">
    //           <InputLabel id="demo-simple-select-standard-label">
    //             Available
    //           </InputLabel>
    //           <Select
    //             labelId="demo-simple-select-standard-label"
    //             onChange={(event) => {
    //               filterList[index][0] = event.target.value;
    //               onChange(filterList[index], index, column);
    //             }}
    //             value={filterList[index]}
    //           >
    //             <MenuItem value={false}>{"Yes"}</MenuItem>
    //             <MenuItem value={true}>{"No"}</MenuItem>
    //           </Select>
    //         </FormControl>
    //       ),
    //     },
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "center" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           {value ? (
    //             <span className="text-warning text-center">No</span>
    //           ) : (
    //             <span className="text-success text-center">Yes</span>
    //           )}
    //         </>
    //       );
    //     },
    //   },
    // },
    {
      label: "Storage Location",
      name: "storage",
      options: {
        filter: false,
        setCellProps: () => ({ style: { whiteSpace: "pre" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          {
            return value ? (
              <small>
                <b>{value}</b>
              </small>
            ) : (
              "-"
            );
          }
        },
      },
    },
    // {
    //   label: "RTS",
    //   name: "rts",
    //   options: {
    //     filter: true,
    //     filterType: "custom",
    //     customFilterListOptions: {
    //       render: (v) => {
    //         if (v.length !== 0) {
    //           return v[0] ? "Yes" : "No";
    //         }
    //         return false;
    //       },
    //     },
    //     filterOptions: {
    //       names: [],
    //       logic(status, filter) {
    //         if (filter.length > 0) {
    //           return status !== filter[0];
    //         }
    //         return false;
    //       },
    //       display: (filterList, onChange, index, column) => (
    //         <FormControl variant="standard">
    //           <InputLabel id="demo-simple-select-standard-label">
    //             RTS
    //           </InputLabel>
    //           <Select
    //             labelId="demo-simple-select-standard-label"
    //             onChange={(event) => {
    //               filterList[index][0] = event.target.value;
    //               onChange(filterList[index], index, column);
    //             }}
    //             value={filterList[index]}
    //           >
    //             <MenuItem value={true}>{"Yes"}</MenuItem>
    //             <MenuItem value={false}>{"No"}</MenuItem>
    //           </Select>
    //         </FormControl>
    //       ),
    //     },
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre", textAlign: "center" },
    //     }),
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <>
    //           {value ? (
    //             <span className="text-success text-center">Yes</span>
    //           ) : (
    //             <span className="text-danger text-center">No</span>
    //           )}
    //         </>
    //       );
    //     },
    //   },
    // },
    // {
    //   label: "Current Status",
    //   name: "status",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     setCellProps: () => ({
    //       style: { whiteSpace: "pre" },
    //     }),
    //   },
    // },
    {
      label: "ACTION",
      name: "action",
      options: {
        filter: false,
        download: false,
        print: false,
        sort: false,
        setCellProps: () => ({
          style: { whiteSpace: "pre" },
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div className="list-icons">
                <div className="list-icons-item dropdown">
                  <button
                    className="list-icons-item dropdown-toggle caret-0 border-0 bg-transparent outline-0"
                    data-toggle="dropdown"
                  >
                    <i className="icon-menu7" />
                  </button>
                  <div className="dropdown-menu dropdown-menu-right">
                    {!value[1] && (
                      <>
                        <a
                          href
                          className="dropdown-item"
                          onClick={() => openSoldModal(value[0])}
                        >
                          <i className="icon-gear" /> Mark as Sold
                        </a>
                      </>
                    )}

                    {value[1] ? (
                      <>
                        <a
                          href
                          className="dropdown-item"
                          onClick={() =>
                            opensetAsUnsoldModal(value[0], value[2])
                          }
                        >
                          <i className="icon-dash" /> Mark as Returned
                        </a>
                      </>
                    ) : (
                      ""
                    )}
                    <a
                      href
                      disabled
                      className="dropdown-item"
                      onClick={() =>
                        openAddColorModal(
                          value[3],
                          value[0],
                          value[5] ? "Update Color" : "Add Color"
                        )
                      }
                    >
                      <i className="icon-add" />
                      {value[5] ? "Update" : "Add"} Color
                    </a>
                    <a
                      href
                      disabled
                      className="dropdown-item"
                      onClick={() => openUpdateInvModel(value[0], value[4])}
                    >
                      <i className="icon-pencil" />
                      Update Device Paramaters
                    </a>
                    <a
                      href
                      disabled
                      className="dropdown-item"
                      onClick={() => openStoreModal(value[4])}
                    >
                      <i className="icon-stack-check" />
                      Assign to Store
                    </a>

                    {/* <a
                      href
                      disabled
                      className="dropdown-item"
                      onClick={() => openDeleteInvModel(value[0], value[4])}
                    >
                      <i className="icon-trash" />
                      Delete
                    </a> */}
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    },
  ];
  const handleResetSearch = () => {
    setImeiSearchText("");
    setSerialSearchText("");
    getInven();
  };
  const handleImeiSearch = () => {
    setPageData({
      isLoading: false,
      rowData: [],
      totalProducts: 0,
    });
    // Normalize IMEI and serial inputs (split by comma or newline, trim, and filter empty values)
    const imeis = imeiSearchText
      .split(/[,\n]+/) // Use + to handle multiple commas/newlines
      .map((imei) => imei.trim())
      .filter((imei) => imei.length > 0);

    const serials = serialSearchText
      .split(/[,\n]+/)
      .map((serial) => serial.trim())
      .filter((serial) => serial.length > 0);

    // Ensure at least one valid IMEI or serial is provided before filtering
    if (imeis.length === 0 && serials.length === 0) {
      setFilteredData(pageData.rowData); // Reset to full data if no search terms
      return;
    }

    // Filter data by IMEIs or serials
    const filteredData = pageData.rowData.filter((item) => {

      const imeiMatch = imeis.some((imei) => item.imei.includes(imei));
      const serialMatch = serials.some((serial) =>
        item.serial_number.includes(serial)
      );
      return imeiMatch || serialMatch;
    });
    setPageData({
      isLoading: false,
      rowData: filteredData,
      totalProducts: filteredData.length,
    });
    // Update state with filtered data and clear selected rows/requests
    // setFilteredData(filteredData);
    // setSelectedRowsData([]);
    // setSelectedRepairRequest([]);
  };
  return (
    <>
      <div
        className="dashboard-container"
        style={{
          padding: "16px",
          backgroundColor: "#f4f4f9",
          minHeight: "100vh",
        }}
      >
        {/* Search Box */}
        <Container
          style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
            marginBottom: "16px",
            maxWidth: "100%",
            textAlign: "center",
          }}
        >
          <h3
            style={{
              marginBottom: "12px",
              fontSize: "20px",
              fontWeight: "600",
              color: "#58cab9",
            }}
          >
            🔎 Search Devices
          </h3>

          <div
            className="search-box"
            style={{
              display: "flex",
              gap: "16px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {/* IMEI Search */}
            <div style={{ flex: "1 1 280px" }}>
              <label
                style={{
                  fontWeight: "500",
                  marginBottom: "6px",
                  display: "block",
                }}
              >
                IMEI Search
              </label>
              <textarea
                value={imeiSearchText}
                onChange={(e) => setImeiSearchText(e.target.value)}
                placeholder="Enter IMEIs (comma-separated or one per line)"
                className="form-control"
                style={{
                  width: "100%",
                  height: "60px",
                  resize: "none",
                  borderColor: "#ccc",
                  borderRadius: "6px",
                  padding: "8px",
                }}
              />
            </div>

            {/* OR Divider */}
            <div
              className="or-divider"
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: "600",
                color: "#777",
              }}
            >
              OR
            </div>

            {/* Serial Search */}
            <div style={{ flex: "1 1 280px" }}>
              <label
                style={{
                  fontWeight: "500",
                  marginBottom: "6px",
                  display: "block",
                }}
              >
                Serial Number Search
              </label>
              <textarea
                value={serialSearchText}
                onChange={(e) => setSerialSearchText(e.target.value)}
                placeholder="Enter Serial Numbers (comma-separated or one per line)"
                className="form-control"
                style={{
                  width: "100%",
                  height: "60px",
                  resize: "none",
                  borderColor: "#ccc",
                  borderRadius: "6px",
                  padding: "8px",
                }}
              />
            </div>
          </div>

          {/* Action Buttons */}
          <div
            style={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "center",
              gap: "12px",
            }}
          >
            <Button
              type="primary"
              onClick={handleImeiSearch}
              className="dt-button buttons-pdf buttons-html5 btn bg-primary legitRipple "
              icon="🔍"
              style={{ padding: "6px 20px" }}
            >
              Search
            </Button>
            <Button onClick={handleResetSearch} style={{ padding: "6px 20px" }}>
              🔄 Reset
            </Button>
          </div>
        </Container>

        {/* Loader (optional) */}
        {loadingInventory && (
          <div
            className="loader"
            style={{ textAlign: "center", marginTop: "16px" }}
          >
            <Loader />
          </div>
        )}

        {/* Table Section */}
        {!loadingInventory && (
          <div className="data-table-container">
            <div
              className="card"
              style={{
                backgroundColor: "#fff",
                padding: "16px",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
              }}
            >
              <div
                className="table-header"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  RTS - Total Rows: <b>{rowCount}</b>
                </div>
              </div>
              <Table
                title={"RTS"}
                data={pageData.rowData}
                columns={columns}
                option={{
                  customToolbar: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <div
                        style={{
                          marginBottom: "16px",
                          fontSize: "18px",
                          marginLeft: "-58px",
                        }}
                      >
                        Total Row Count: <b>{rowCount}</b>
                      </div> */}
                    </div>
                  ),
                  onTableChange: (action, tableState) => {
                    if (action === "filterChange" || action === "search") {
                      setFilteredData(
                        tableState.displayData.map((row) => row.data)
                      );
                    }
                  },
                  selectableRows: "multiple",
                  onRowSelectionChange: handleRowSelectionChange,
                  customToolbarSelect: (
                    selectedRows,
                    displayData,
                    setSelectedRows
                  ) => {
                    const actions = [
                      {
                        name: "Bulk Update",
                        onClick: () => openBulkUpdate(selectedRepairRequest),
                      },
                      {
                        name: "Bulk Delete",
                        onClick: () => openBulkDelete(selectedRepairRequest),
                      },
                      {
                        name: "Push to INBOUND",
                        onClick: () =>
                          openReturnToWIPModel(selectedRepairRequest),
                      },
                      {
                        name: "Country Transfer",
                        onClick: () =>
                          openTransferCountryTransfer(selectedRepairRequest),
                      },
                      {
                        name: "Edit Location",
                        onClick: () =>
                          openEditLocationModel(selectedRepairRequest),
                      },
                      // { name: 'Transfer Consignment', onClick: () => openTransferConsignmentModel(selectedRepairRequest) }
                    ];
                    const menu = (
                      <Menu>
                        {actions.map((action, index) => (
                          <Menu.Item key={index} onClick={action.onClick}>
                            {action.name}
                          </Menu.Item>
                        ))}
                      </Menu>
                    );
                    return (
                      <div>
                        <Dropdown overlay={menu}>
                          <Button>
                            <Space>
                              Actions
                              <DownOutlined />
                            </Space>
                          </Button>
                        </Dropdown>
                        &emsp;
                      </div>
                    );
                    {
                      /* <Dropdown menu={menu}>
                      <Button>
                        <Space>
                          Action
                          <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown> */
                    }
                    {
                    }
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ListProducts;
